import { ColorResult, SketchPicker } from 'react-color';
import Tooltip from 'components/Tooltip';
import React from 'react';
import Field from './Field';

interface Props extends Omit<React.ComponentProps<typeof Field>, 'children'> {
  value: string;
  onChange: (value: string) => void;
  defaults?: string[];
}

export default function ColorPicker(props: Props) {
  const { value, onChange, defaults, ...fieldProps } = props;

  const handleChange = (value: ColorResult) => {
    onChange(value.hex);
  };

  return (
    <Field {...fieldProps}>
      <div className="flex items-center space-x-1">
        <Tooltip
          theme="none"
          interactive
          placement="auto-start"
          content={
            <div>
              <SketchPicker onChange={handleChange} color={value} />
            </div>
          }
          trigger="click"
        >
          <button
            type="button"
            className="w-8 h-4 rounded-lg border-default"
            style={{ backgroundColor: value }}
          />
        </Tooltip>

        <div className="text-dark text-14 bump-up-2">
          {value?.toUpperCase()}
        </div>
      </div>
    </Field>
  );
}
