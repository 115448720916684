import { useHistory, useParams } from 'react-router';
import React from 'react';
import FanSubmission from 'types/FanSubmission';
import {
  FanSubmissionThumbnailCheckbox,
  FanSubmissionThumbnailActions,
} from './components';
import routes from 'helpers/routes';
import routeFor from 'helpers/routeFor';
import FanRequestType from 'types/FanRequestType';
import FanSubmissionThumbnailContainer from '../FanSubmissionThumbnailContainer';
import FanRequest from 'types/FanRequest';

interface Props {
  fanRequest: FanRequest;
  fanSubmission: FanSubmission;
  isSelected: boolean;
  isSelectMode: boolean;
  onToggleSelected: () => void;
}

export default function FanSubmissionThumbnail(props: Props) {
  const history = useHistory();
  const params = useParams<{ id: string; type: FanRequestType }>();
  const {
    fanSubmission,
    fanRequest,
    isSelected,
    onToggleSelected,
    isSelectMode,
  } = props;

  const handleClick = () => {
    if (isSelectMode) {
      onToggleSelected();
    } else {
      history.push(
        `${routeFor(
          routes.requests.fanSubmissionShow,
          params.type,
          params.id,
          fanSubmission.id
        )}${window.location.search}`
      );
    }
  };

  return (
    <FanSubmissionThumbnailContainer
      width={fanSubmission.width}
      height={fanSubmission.height}
      isSelected={isSelected}
    >
      <div className="absolute inset-0 z-10 pointer-events-none bg-thumbnailOverlay opacity-0 group-hover:opacity-100 transition-fast" />

      <FanSubmissionThumbnailCheckbox
        checked={isSelected}
        isSelectMode={isSelectMode}
        onChange={onToggleSelected}
      />

      <FanSubmissionThumbnailActions
        fanRequest={fanRequest}
        fanSubmission={fanSubmission}
      />

      <button
        onClick={handleClick}
        className="absolute inset-0 w-full h-full bg-cover"
        style={{ backgroundImage: `url(${fanSubmission.thumbnailUrl})` }}
      />

      {isSelected && (
        <div
          className="absolute z-10 rounded-lg inset-0 pointer-events-none"
          style={{ boxShadow: 'inset 0 0 0 1px #fff' }}
        />
      )}
    </FanSubmissionThumbnailContainer>
  );
}
