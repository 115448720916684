import { IconHome, IconErrorPage } from 'icons';
import { useTranslation } from 'react-i18next';

export default function ErrorPage() {
  const { t } = useTranslation();

  return (
    <div className="h-screen w-screen">
      <div className="fixed top-0 left-0 right-0 flex items-center border-b-default h-7 px-2">
        <a
          href="/"
          title={t('errorPage.home')}
          className="rounded-full bg-greyBg w-5 h-5 p-1"
        >
          <IconHome className="w-full h-full text-dark" />
        </a>
      </div>

      <div className="absolute-center text-center w-full">
        <div className="mx-auto w-12 h-12 mb-4.5">
          <IconErrorPage className="w-full h-full" />
        </div>

        <h4 className="h4 mb-1 px-2">{t('errorPage.heading')}</h4>

        <div className="text-light text-12 mb-2 px-2">
          {t('errorPage.subheading')}
        </div>

        <button
          onClick={() => window.location.reload()}
          className="btn btn--socialie btn--inlineBlock"
        >
          {t('errorPage.reload')}
        </button>
      </div>
    </div>
  );
}
