import api from './index';
import camelize from 'camelize';
import Invoice from 'types/Invoice';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchInvoices: builder.query<Invoice[], void>({
      query: () => ({ url: 'invoices' }),
      transformResponse: camelize,
    }),

    fetchInvoice: builder.query<{ url: string }, Invoice['id']>({
      query: (id) => ({ url: `invoices/${id}` }),
      transformResponse: camelize,
    }),
  }),
});

export default extendedApi;

export const { useFetchInvoicesQuery, useLazyFetchInvoiceQuery } = extendedApi;
