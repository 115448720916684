import { useFetchInvitationQuery } from 'api/invitations';
import AuthPage from 'components/AuthPage';
import LoadingPage from 'components/LoadingPage';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import MfaLogIn from 'screens/Auth/screens/MfaLogIn';
import SemiLoggedInUser from 'types/SemiLoggedInUser';
import User from 'types/User';
import {
  InvitationAccept,
  InvitationLogIn,
  InvitationNewUser,
  InvitationWrongUser,
} from './components';

interface Props {
  currentUser?: User | null;
  semiLoggedInUser?: SemiLoggedInUser | null;
}

export default function Invitation(props: Props) {
  const { currentUser, semiLoggedInUser } = props;

  const { code } = useParams<{ code: string }>();

  const {
    data: invitation,
    isLoading,
    error,
  } = useFetchInvitationQuery({ code });
  const { t } = useTranslation();
  const heading = error
    ? t('invitation.404.heading')
    : invitation
    ? t('invitation.heading', { name: invitation.fanAccount.name })
    : undefined;

  if (semiLoggedInUser) {
    return <MfaLogIn semiLoggedInUser={semiLoggedInUser} />;
  }

  return (
    <AuthPage heading={heading}>
      {isLoading ? (
        <LoadingPage />
      ) : error || !invitation ? (
        t('invitation.404.description')
      ) : currentUser && invitation.user.id !== currentUser.id ? (
        <InvitationWrongUser
          invitation={invitation}
          currentUser={currentUser}
        />
      ) : currentUser ? (
        <InvitationAccept code={code} />
      ) : invitation.user.pending ? (
        <InvitationNewUser code={code} invitation={invitation} />
      ) : (
        <InvitationLogIn invitation={invitation} />
      )}
    </AuthPage>
  );
}
