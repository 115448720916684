import routeFor from 'helpers/routeFor';
import routes from 'helpers/routes';
import FanRequest from 'types/FanRequest';
import { Redirect } from 'react-router';
import {
  FanRequestTopBar,
  FanRequestEmpty,
  FanRequestSubmissions,
} from './components';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

interface Props {
  fanRequest?: FanRequest;
}

export default function FanRequestsShow(props: Props) {
  const { fanRequest } = props;
  const { t } = useTranslation();

  if (fanRequest?.status === 'draft') {
    return (
      <Redirect to={routeFor(routes.requests.edit, 'draft', fanRequest.id)} />
    );
  }

  return fanRequest ? (
    <>
      <Helmet
        title={t('fanRequestShow.pageTitle', {
          subject: fanRequest.subject || t('global.noSubject'),
        })}
      />

      <FanRequestTopBar fanRequest={fanRequest} />
      {fanRequest.fanSubmissionsCount === 0 ? (
        <FanRequestEmpty fanRequest={fanRequest} />
      ) : (
        <FanRequestSubmissions fanRequest={fanRequest} />
      )}
    </>
  ) : (
    <div className="notice notice--error">!!!</div>
  );
}
