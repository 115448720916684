import FanRequestPreview from 'components/FanRequestPreview';
import FanAccountParams from 'types/FanAccountParams';
import placeholderCoverPhotoUrl from 'images/party.jpg';
import { useTranslation } from 'react-i18next';

interface Props {
  logoUrl?: string;
  sponsorLogoUrl?: string;
  values: FanAccountParams;
}

export default function SettingsBrandPreview(props: Props) {
  const { logoUrl, values } = props;
  const { t } = useTranslation();
  return (
    <FanRequestPreview
      values={{
        subject: t('settings.brand.preview.subject'),
        instructions: t('settings.brand.preview.instructions'),
        mediaType: 'video',
        videoDuration: 60,
        backgroundBlurAmount: '20px',
        backgroundColor: '#333333',
        backgroundOverlayOpacity: 0.2,
        introButtonText: t('settings.brand.preview.introButtonText'),
        introDescription: t('settings.brand.preview.introDescription'),
        introHeader: t('settings.brand.preview.introHeader'),
        orientation: 'any',
      }}
      dataUrls={{ coverPhoto: placeholderCoverPhotoUrl }}
      fanAccount={{ ...values, logoUrl }}
    />
  );
}
