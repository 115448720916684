import React, { useState, useRef, useEffect } from 'react';
import Context from './Context';
import uniqueId from 'lodash/uniqueId';
import {
  FlashMessage,
  CreateFlashMessageFn,
  CreateFlashMessageOptions,
} from './types';

interface Props {
  children: React.ReactNode;
}

const defaultCreateOptions: CreateFlashMessageOptions = {
  isError: false,
  timeout: 5000,
};

export default function FlashMessageProvider({ children }: Props) {
  const [messages, setMessages] = useState<FlashMessage[]>([]);
  const timeouts = useRef<number[]>([]);

  const removeMessage = (id: string) => {
    setMessages((messages) => messages.filter((msg) => msg.id !== id));
  };

  // Clear any timeouts on unmount
  useEffect(() => {
    const currentTimeouts = timeouts.current;
    return () => {
      currentTimeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, []);

  const createMessage: CreateFlashMessageFn = (text, options) => {
    const opts = { ...defaultCreateOptions, ...options };
    const id = uniqueId();
    const message = { text, id, ...opts };
    setMessages((messages) => [...messages, message]);
    if (opts.timeout) {
      timeouts.current.push(
        window.setTimeout(() => removeMessage(id), opts.timeout)
      );
    }
  };

  return (
    <Context.Provider value={{ messages, createMessage, removeMessage }}>
      {children}
    </Context.Provider>
  );
}
