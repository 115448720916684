import routes from 'helpers/routes';
import {
  IconAvatar,
  IconBranding,
  IconGroups,
  IconLive,
  IconSubscription,
} from 'icons';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export default function SettingsNav() {
  const { t } = useTranslation();
  const linkProps = {
    className:
      'block mb-0.25 h-5 rounded-lg text-dark font-semibold p-1 hover:bg-grey8',
    activeClassName: 'bg-grey7 hover:!bg-grey7',
  };

  return (
    <div className="sidebar bg-grey9 p-2 with-topbar flex flex-col items-stretch">
      <div className="flex items-center ">
        <div className="h5 bump-up-1 mb-2">{t('settings.nav.heading')}</div>
      </div>

      <NavLink to={routes.settings.profile} {...linkProps}>
        <div className="flex items-center space-x-0.5">
          <IconAvatar className="w-2.5 h-2.5" />
          <span>{t('settings.nav.myProfile')}</span>
        </div>
      </NavLink>

      <NavLink to={routes.settings.brand} {...linkProps}>
        <div className="flex items-center space-x-0.5">
          <IconBranding className="w-2.5 h-2.5 text-grey6" />
          <span>{t('settings.nav.brand')}</span>
        </div>
      </NavLink>

      <NavLink to={routes.settings.users} {...linkProps}>
        <div className="flex items-center space-x-0.5">
          <IconGroups className="w-2.5 h-2.5 text-grey6" />
          <span>{t('settings.nav.users')}</span>
        </div>
      </NavLink>

      <NavLink to={routes.settings.domains.root} {...linkProps}>
        <div className="flex items-center space-x-0.5">
          <IconLive className="w-2.5 h-2.5 text-grey6" />
          <span>{t('settings.nav.domains')}</span>
        </div>
      </NavLink>

      <NavLink to={routes.settings.subscription} {...linkProps}>
        <div className="flex items-center space-x-0.5">
          <IconSubscription className="w-2.5 h-2.5 text-grey6" />
          <span>{t('settings.nav.subscription')}</span>
        </div>
      </NavLink>

      <div className="mt-auto border-t-default pt-1">
        <a
          className="text-12 text-bodyText hover:underline"
          href="https://www.socialie.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="mb-px">{t('global.privacyPolicy')}</span>
        </a>
      </div>
    </div>
  );
}
