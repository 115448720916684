import FanSubmissionDeleteModal from 'components/FanSubmissionDeleteModal';
import FanSubmissionRating from 'components/FanSubmissionRating';
import formatDate from 'helpers/formatDate';
import { IconTrash } from 'icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FanRequest from 'types/FanRequest';
import FanSubmission from 'types/FanSubmission';
import {
  FanSubmissionSidebarDownload,
  FanSubmissionSidebarNavigation,
  FanSubmissionSidebarShortcuts,
  FanSubmissionSidebarMetadata,
} from './components';
import FanSubmissionSidebarDataCapture from './components/FanSubmissionSidebarDataCapture';

interface Props {
  fanSubmission?: FanSubmission;
  fanRequest: FanRequest;
  isFetching?: boolean;
  onClose: () => void;
  onOpenNext?: () => void;
  onOpenPrev?: () => void;
}

export default function FanSubmissionSidebar(props: Props) {
  const {
    fanSubmission,
    fanRequest,
    isFetching,
    onClose,
    onOpenNext,
    onOpenPrev,
  } = props;
  const { t } = useTranslation();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return (
    <>
      <div className="w-36 p-2 bg-white flex flex-col pointer-events-auto">
        <div className="flex-1">
          {!!fanSubmission && !isFetching && (
            <>
              <div className="pb-3.5 mb-2.5 border-b-default">
                <div className="flex items-center mb-2">
                  <FanSubmissionRating
                    fanSubmission={fanSubmission}
                    theme="sidebar"
                    keyboard
                  />

                  <button
                    className="text-grey5 w-4.5 h-4.5 p-1 hover:bg-grey8 hover:text-dark rounded-full"
                    onClick={() => setIsDeleteModalOpen(true)}
                    aria-label={t('fanRequestShow.submissions.sidebar.delete')}
                  >
                    <IconTrash className="block w-full h-full" />
                  </button>

                  <div className="ml-auto">
                    <FanSubmissionSidebarNavigation
                      onOpenNext={onOpenNext}
                      onOpenPrev={onOpenPrev}
                    />
                  </div>
                </div>

                <FanSubmissionSidebarDownload fanSubmission={fanSubmission} />
              </div>
              <div className="mb-4">
                <div className="font-black text-dark text-20 leading-tight">
                  {fanRequest.subject}
                </div>
                <div className="text-14 text-light">
                  {t('fanRequestShow.submissions.sidebar.submittedDate', {
                    date: formatDate(fanSubmission.createdAt, {
                      timeZone: false,
                    }),
                  })}
                </div>
              </div>

              <FanSubmissionSidebarMetadata fanSubmission={fanSubmission} />
              <FanSubmissionSidebarDataCapture fanSubmission={fanSubmission} />
            </>
          )}
        </div>

        <FanSubmissionSidebarShortcuts />
      </div>

      {fanSubmission && (
        <FanSubmissionDeleteModal
          fanRequest={fanRequest}
          fanSubmissions={[fanSubmission]}
          isOpen={isDeleteModalOpen}
          onRequestClose={() => setIsDeleteModalOpen(false)}
          onAfterDelete={onClose}
        />
      )}
    </>
  );
}
