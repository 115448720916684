import routes from 'helpers/routes';
import useBodyClassName from 'helpers/useBodyClassName';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { selectSemiLoggedInUser } from 'redux/reducers/auth';
import { useAppSelector } from 'redux/store';
import User from 'types/User';
import LogIn from './screens/LogIn';
import MfaLogIn from './screens/MfaLogIn';
import SelectFanAccount from './screens/SelectFanAccount';
import SignUp from './screens/SignUp/SignUp';
import MagicSignIn from './screens/MagicSignIn';
import Invitation from 'screens/Invitation';

interface Props {
  currentUser?: User | null;
}

export default function Auth({ currentUser }: Props) {
  const semiLoggedInUser = useAppSelector(selectSemiLoggedInUser);
  useBodyClassName('bg-greyBg');
  return currentUser ? (
    <Switch>
      <Route path={routes.auth.signUp}>
        <SignUp />
      </Route>

      <Route>
        <SelectFanAccount currentUser={currentUser} />
      </Route>
    </Switch>
  ) : semiLoggedInUser ? (
    <Switch>
      <Route path={routes.auth.logIn}>
        <MfaLogIn semiLoggedInUser={semiLoggedInUser} />
      </Route>

      <Route path={routes.auth.signUp}>
        <SignUp />
      </Route>

      <Route path={routes.invitation}>
        <Invitation semiLoggedInUser={semiLoggedInUser} />
      </Route>

      <Redirect to={routes.auth.logIn} />
    </Switch>
  ) : (
    <Switch>
      <Route path={routes.auth.logIn}>
        <LogIn />
      </Route>

      <Route path={routes.auth.signUp}>
        <SignUp />
      </Route>

      <Route path={routes.auth.magicSignIn}>
        <MagicSignIn />
      </Route>

      <Route path={routes.invitation}>
        <Invitation />
      </Route>

      <Redirect to={routes.auth.logIn} />
    </Switch>
  );
}
