import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFlashMessages from './useFlashMessages';

interface Options {
  billingZip: string;
  onSuccess: (token: string) => void;
  onError?: (error: any) => void;
}

export default function useTokenizeCard(cardRef: React.RefObject<any>) {
  const { t } = useTranslation();
  const { addFlashMessage } = useFlashMessages();
  const [isTokenizing, setIsTokenizing] = useState(false);

  const reportError = () => {
    addFlashMessage(t('onboarding.signUp.payment.cardError'), {
      isError: true,
    });
  };

  const tokenizeCard = async (options: Options) => {
    if (!cardRef.current) return;
    setIsTokenizing(true);

    try {
      const result = await cardRef.current.tokenize({
        billingZip: options.billingZip,
      });

      if (result?.token) {
        options.onSuccess(result.token);
      } else {
        if (result?.error) console.error(result.error);
        options.onError ? options.onError(result.error) : reportError();
      }
    } catch (e) {
      console.error(e);
      options.onError ? options.onError(e) : reportError();
    } finally {
      setIsTokenizing(false);
    }
  };

  return [tokenizeCard, { isTokenizing }] as [
    typeof tokenizeCard,
    { isTokenizing: boolean }
  ];
}
