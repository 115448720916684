import React, { useState } from 'react';
import { selectCurrentUser } from 'redux/reducers/auth';
import { useAppSelector } from 'redux/store';
import {
  OnboardingBrand,
  OnboardingIntro,
  OnboardingUsers,
  OnboardingStyles,
} from './components';

const steps = ['intro', 'brand', 'users', 'styles'] as const;
type Step = typeof steps[number];

export default function Onboarding() {
  const fanAccount = useAppSelector(selectCurrentUser)?.currentFanAccount;

  const [step, setStep] = useState<Step>(steps[0]);

  if (!fanAccount || fanAccount.onboardingCompleted) return null;

  const components: { [key in Step]: JSX.Element } = {
    intro: <OnboardingIntro onContinue={() => setStep('brand')} />,
    brand: (
      <OnboardingBrand
        fanAccount={fanAccount}
        onContinue={() => setStep('users')}
      />
    ),
    users: <OnboardingUsers onContinue={() => setStep('styles')} />,
    styles: <OnboardingStyles />,
  };

  return components[step];
}
