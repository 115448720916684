import cx from 'classnames';
import Tooltip from 'components/Tooltip';
import { IconCheck, IconLive, IconWarning } from 'icons';
import { useTranslation } from 'react-i18next';
import Domain from 'types/Domain';
import { useHistory } from 'react-router';
import routeFor from 'helpers/routeFor';
import routes from 'helpers/routes';

interface Props {
  domain: Domain;
}

export default function SettingsDomainsDNS({ domain }: Props) {
  const { t } = useTranslation();
  const { dnsComplete } = domain;
  const history = useHistory();
  const msg = dnsComplete
    ? 'settings.domains.dns.tooltipComplete'
    : 'settings.domains.dns.tooltipIncomplete';

  return (
    <>
      <Tooltip content={t(msg)} theme="socialieDark" placement="top" arrow>
        <div>
          <button
            disabled={dnsComplete}
            className="relative w-4 h-4"
            onClick={() =>
              history.push(routeFor(routes.settings.domains.dns, domain.id))
            }
          >
            <IconLive className="text-lightIcon block w-full h-full" />
            <span
              className={cx('absolute bottom-0 right-0 w-2 h-2 rounded-full', {
                'bg-success p-[3px] border border-solid border-white':
                  dnsComplete,
              })}
            >
              {dnsComplete ? (
                <IconCheck className="w-full h-full text-white" />
              ) : (
                <IconWarning className="text-yellow bg-white rounded-full w-full h-full" />
              )}
            </span>
          </button>
        </div>
      </Tooltip>
    </>
  );
}
