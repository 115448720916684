import { useLogOutMutation } from 'api/auth';
import { useSwitchFanAccountMutation } from 'api/fanAccount';
import routeFor from 'helpers/routeFor';
import routes from 'helpers/routes';
import useFlashMessages from 'hooks/useFlashMessages';
import { IconCaretRight, IconLoader } from 'icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import User from 'types/User';
import AuthPage from 'components/AuthPage';

interface Props {
  currentUser: User;
}

export default function SelectFanAccount({ currentUser }: Props) {
  const { fanAccounts } = currentUser;
  const { t } = useTranslation();
  const { addFlashMessage } = useFlashMessages();
  const [loadingAccountId, setLoadingAccountId] = useState<number | null>(null);

  const [logOut] = useLogOutMutation();
  const [switchFanAccount] = useSwitchFanAccountMutation();

  const handleLogOut = async () => {
    await logOut();
    window.location.reload();
  };

  const handleSwitchAccount = async (id: number) => {
    setLoadingAccountId(id);
    const response = await switchFanAccount({ id });
    setLoadingAccountId(null);

    if ('error' in response) {
      addFlashMessage(t('global.unexpectedError'), { isError: true });
    } else {
      window.location.href = routeFor(routes.requests.index, 'live');
    }
  };

  if (!fanAccounts.length) {
    return (
      <AuthPage heading={t('auth.selectFanAccount.noAccounts.heading')}>
        {t('auth.selectFanAccount.noAccounts.description')}

        <div className="mt-4 flex items-center space-x-1">
          <a
            href="https://socialiefan.com"
            target="_blank"
            className="btn btn--grey btn--inlineBlock"
            rel="noreferrer"
          >
            {t('auth.selectFanAccount.noAccounts.learnMore')}
          </a>

          <button
            className="btn btn--inlineBlock btn--socialie"
            onClick={() => handleLogOut()}
          >
            {t('auth.selectFanAccount.noAccounts.signOut')}
          </button>
        </div>
      </AuthPage>
    );
  }

  return (
    <AuthPage heading={t('auth.selectFanAccount.heading')}>
      {fanAccounts.map((fanAccount) => (
        <button
          key={fanAccount.id}
          className="block w-full mb-1 last:mb-0 rounded-lg border-default px-2 py-1.5 hover:bg-grey9 transition-default"
          onClick={() => handleSwitchAccount(fanAccount.id)}
          disabled={!!loadingAccountId}
        >
          <span className="flex items-center">
            <span className="mr-2 flex-shrink-0 w-7 h-7 bg-greyBg rounded-full overflow-hidden">
              {fanAccount.logoUrl && (
                <img
                  src={fanAccount.logoUrl}
                  className="w-full h-full rounded-full object-cover"
                  alt="Logo"
                />
              )}
            </span>

            <span className="flex-1 truncate mr-2 text-20 font-black text-dark">
              {fanAccount.name}
            </span>

            {React.createElement(
              loadingAccountId === fanAccount.id ? IconLoader : IconCaretRight,
              { className: 'w-4 h-4 flex-shrink-0 text-lightIcon' }
            )}
          </span>
        </button>
      ))}

      <button
        className="mt-4 btn btn--inlineBlock btn--socialie px-4"
        onClick={() => handleLogOut()}
      >
        {t('auth.selectFanAccount.noAccounts.signOut')}
      </button>
    </AuthPage>
  );
}
