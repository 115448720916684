import { useFetchSubscriptionQuery } from 'api/subscriptions';
import { SecondaryTopBar } from 'components/Layout/components';
import LoadingPage from 'components/LoadingPage';
import { IconWarning } from 'icons';
import { useTranslation } from 'react-i18next';
import {
  SettingsSubscriptionBilling,
  SettingsSubscriptionError,
  SettingsSubscriptionInvoices,
  SettingsSubscriptionPlan,
  SettingsSubscriptionUsage,
} from './components';

export default function SettingsSubscription() {
  const { t } = useTranslation();
  const { data: subscription, isLoading, error } = useFetchSubscriptionQuery();

  return isLoading ? (
    <LoadingPage />
  ) : error || !subscription ? (
    <SettingsSubscriptionError />
  ) : (
    <>
      <SecondaryTopBar
        heading={t('settings.subscription.heading')}
        subHeading={t('settings.subscription.subHeading')}
      />
      <div className="pt-17">
        <div className="w-96 max-w-full mx-auto p-2">
          {subscription.status === 'cancelled' && (
            <div className="notice notice--error">
              <div className="flex items-center">
                <IconWarning className="w-4 h-4 mr-1" />
                {t('settings.subscription.inactive')}
              </div>
            </div>
          )}
          <SettingsSubscriptionPlan subscription={subscription} />
          <SettingsSubscriptionUsage />
          <SettingsSubscriptionBilling subscription={subscription} />
          <SettingsSubscriptionInvoices />
        </div>
      </div>
    </>
  );
}
