import routeFor from 'helpers/routeFor';
import cx from 'classnames';
import routes from 'helpers/routes';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import FanRequest from 'types/FanRequest';
import StatusPill from 'components/StatusPill';
import getTypeFromFanRequest from 'helpers/getTypeFromFanRequest';

interface Props {
  fanRequest: FanRequest;
}

export default function FanRequestsSidebarItem({ fanRequest }: Props) {
  const id = parseInt(useParams<{ id?: string }>().id || '', 10);
  const isActive = fanRequest.id === id;
  const { t } = useTranslation();

  return (
    <Link
      className={cx('flex items-center p-1 rounded-xl ', {
        'bg-grey8': isActive,
        'hover:bg-grey9': !isActive,
      })}
      to={routeFor(
        routes.requests[fanRequest.status === 'draft' ? 'edit' : 'show'],
        getTypeFromFanRequest(fanRequest),
        fanRequest.id
      )}
    >
      <div className="flex-1 mr-3 overflow-hidden">
        <div className="text-dark text-14 w-full overflow-hidden font-semibold truncate">
          {fanRequest.subject || t('global.noSubject')}
        </div>

        <div
          className={cx(
            'text-12 mb-0.5 last:mb-0',
            isActive ? 'text-dark' : 'text-light'
          )}
        >
          /{fanRequest.urlSlug}
        </div>

        {(!fanRequest.expiresAt ||
          fanRequest.expiresAt > new Date().getTime() ||
          fanRequest.status === 'draft') && (
          <StatusPill fanRequest={fanRequest} size="small" />
        )}
      </div>

      <div className="border-default rounded-full h-2.5 px-0.5 bg-white text-dark text-12 font-bold min-w-4 text-center">
        <span className="bump-up-1">{fanRequest.fanSubmissionsCount}</span>
      </div>
    </Link>
  );
}
