import api from './index';
import camelize from 'camelize';
import FetchCurrentUserResponse from 'types/FetchCurrentUserResponse';
import LogInResponse from 'types/LogInResponse';
import LogInRequest from 'types/LogInRequest';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchCurrentUser: builder.query<FetchCurrentUserResponse, void>({
      query: () => ({ url: 'users/current' }),
      transformResponse: camelize,
    }),

    logIn: builder.mutation<LogInResponse, LogInRequest>({
      query: (params) => ({
        url: 'users/log-in',
        method: 'POST',
        body: params,
      }),
      transformResponse: camelize,
      invalidatesTags: ['FanPlan'],
    }),

    logOut: builder.mutation<void, void>({
      query: () => ({ url: 'users/log-out', method: 'POST' }),
    }),
  }),
});

export default extendedApi;

export const { useFetchCurrentUserQuery, useLogOutMutation, useLogInMutation } =
  extendedApi;
