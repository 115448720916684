import { useTranslation } from 'react-i18next';
import Form from 'components/Form';
import FanRequestType from 'types/FanRequestType';
import { IconLoader } from 'icons';
import FanRequestsSidebarItem from './FanRequestsSidebarItem';
import FanRequest from 'types/FanRequest';
import { useHistory } from 'react-router-dom';
import routeFor from 'helpers/routeFor';
import routes from 'helpers/routes';
import usePlan from 'hooks/usePlan';
import Usage from 'components/Usage';

interface Props {
  type: FanRequestType;
  fanRequestsByType: { [key in FanRequestType]: FanRequest[] };
  isLoading: boolean;
}

export default function FanRequestsSidebar(props: Props) {
  const { type, fanRequestsByType, isLoading } = props;
  const history = useHistory();
  const fanRequests = fanRequestsByType[type];

  const { t } = useTranslation();

  const plan = usePlan();

  const allTypes = ['live', 'expired', 'draft'] as FanRequestType[];

  return (
    <div className="sidebar flex flex-col bg-white with-topbar">
      <div className="bg-grey9 border-b-default pt-2.5 px-1.5 pb-1">
        <div className="flex items-center mb-1">
          <div className="text-dark uppercase text-12 font-bold">
            {t('fanRequests.sidebar.requests')}
          </div>
        </div>

        <div className="mb-0.5">
          <Form.ToggleGroup>
            {allTypes.map((value) => (
              <Form.ToggleGroup.Option
                key={value}
                onSelect={() =>
                  history.push(routeFor(routes.requests.index, value))
                }
                isSelected={type === value}
                title={t(`fanRequestTypes.${value}`)}
              />
            ))}
          </Form.ToggleGroup>
        </div>

        <div className="text-12 text-light">
          {isLoading ? (
            <IconLoader className="block w-2 h-2 text-light" />
          ) : (
            t(`fanRequests.sidebar.requestCount.${type}`, {
              count: fanRequests?.length,
            })
          )}
        </div>
      </div>

      <div className="flex-1 p-1 overflow-y-auto">
        {fanRequests?.map((fanRequest) => (
          <FanRequestsSidebarItem key={fanRequest.id} fanRequest={fanRequest} />
        ))}
      </div>

      {!isLoading && (
        <div className="border-t-default px-1.5 py-1.5">
          {!!plan.maxUploadsPerTerm && (
            <div className="mb-1 last:mb:0 text-light">
              <Usage.Submissions />
            </div>
          )}

          <Usage.Requests used={fanRequestsByType.live.length} />
        </div>
      )}
    </div>
  );
}
