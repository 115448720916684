import usePlan from './usePlan';

export default function useOverLimit(): 'storage' | 'submissions' | false {
  const plan = usePlan();

  if (plan.usage.storageUsed >= plan.maxStorageInGb * 1024 * 1024 * 1024) {
    return 'storage';
  }

  if (plan.usage.submissionCount >= plan.maxUploadsPerTerm) {
    return 'submissions';
  }

  return false;
}
