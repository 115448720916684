import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
} from 'react';
import cx from 'classnames';
import { useCallback } from 'react';

interface Props {
  children: React.ReactNode;
  variant?: 'white' | 'socialie';
}

const ToggleGroupContext = React.createContext({
  onSelect: (idx: number) => {},
  variant: 'socialie' as Props['variant'],
});

export default function ToggleGroup({ variant, children }: Props) {
  const [selectedIdx, setSelectedIdx] = useState<number | null>(null);
  const [selectedDimensions, setSelectedDimensions] = useState({
    width: 0,
    offset: 0,
  });
  const wrapperRef = useRef<HTMLDivElement>(null);

  // Get the selected dimensions when the selected idx changes
  useLayoutEffect(() => {
    if (wrapperRef.current && selectedIdx != null) {
      const child = wrapperRef.current.children[selectedIdx] as HTMLElement;

      if (child) {
        setSelectedDimensions({
          offset: child.offsetLeft,
          width: child.offsetWidth,
        });
      }
    }
  }, [selectedIdx]);

  const handleSelect = useCallback((idx: number) => {
    setSelectedIdx(idx);
  }, []);

  return (
    <div>
      <div
        className="inline-flex items-stretch relative rounded-xl bg-greyBg shadow-inner pl-0.5 py-0.5"
        ref={wrapperRef}
      >
        <ToggleGroupContext.Provider
          value={{ variant, onSelect: handleSelect }}
        >
          {children}
        </ToggleGroupContext.Provider>

        {selectedIdx != null && (
          <div
            className={cx('absolute left-0 rounded-lg transition-fast z-30', {
              'bg-white': variant === 'white',
              'bg-socialiePink': variant === 'socialie',
            })}
            style={{
              transform: `translateX(${selectedDimensions.offset}px)`,
              width: `${selectedDimensions.width}px`,
              top: '4px',
              bottom: '4px',
            }}
          />
        )}
      </div>
    </div>
  );
}

ToggleGroup.defaultProps = {
  variant: 'socialie',
};

ToggleGroup.Option = Option;

interface OptionProps {
  isSelected: boolean;
  onSelect: () => void;
  title: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

function Option(props: OptionProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { isSelected, title, icon } = props;
  const { onSelect, variant } = useContext(ToggleGroupContext);

  useEffect(() => {
    if (buttonRef.current?.parentNode && isSelected) {
      const idx = Array.prototype.indexOf.call(
        buttonRef.current.parentNode.children,
        buttonRef.current
      );
      onSelect(idx);
    }
  }, [isSelected, onSelect]);

  return (
    <button
      ref={buttonRef}
      type="button"
      className={cx(
        'h-4 text-14 px-1.5 mr-0.5 rounded-lg transition-fast bg-transparent z-40',
        {
          'text-body hover:bg-grey7 hover:text-dark ': !isSelected,
          'text-white': isSelected && variant === 'socialie',
          'text-dark': isSelected && variant === 'white',
        }
      )}
      onClick={props.onSelect}
    >
      <span className="flex items-center">
        {!!icon &&
          React.createElement(icon, {
            className: cx('block mr-1 w-2 h-2', {
              'text-light': !isSelected,
              'text-white': isSelected && variant === 'socialie',
              'text-body': isSelected && variant === 'white',
            }),
          })}
        <span className="bump-up-1 font-bold">{title}</span>
      </span>
    </button>
  );
}
