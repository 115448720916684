import AuthPage from 'components/AuthPage';
import LogInForm from 'components/LogInForm';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function LogIn() {
  const { t } = useTranslation();

  return (
    <AuthPage heading={t('auth.logIn.heading')}>
      <div className="w-full">
        <LogInForm />
      </div>
    </AuthPage>
  );
}
