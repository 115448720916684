import FanRequest from 'types/FanRequest';
import FanRequestParams from 'types/FanRequestParams';
import FanRequestType from 'types/FanRequestType';
import api from './index';
import camelize from 'camelize';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchFanRequest: builder.query<FanRequest, { id: number | string }>({
      query: (params) => ({
        url: `requests/${params.id}`,
        method: 'GET',
      }),
      transformResponse: camelize,
      providesTags: (result, error, arg) => [
        { type: 'FanRequest', id: arg.id },
      ],
    }),

    fetchFanRequests: builder.query<FanRequest[], { type?: FanRequestType }>({
      query: ({ type }) => ({
        url: `requests?type=${type || ''}`,
        method: 'GET',
      }),
      transformResponse: camelize,
      providesTags: ['FanRequest'],
    }),

    createFanRequest: builder.mutation<FanRequest, Partial<FanRequestParams>>({
      query: (params) => ({
        url: 'requests',
        method: 'POST',
        body: {
          ...params,
          expiresAt: params.expiresAt ? params.expiresAt / 1000 : undefined,
        },
      }),
      transformResponse: camelize,
      invalidatesTags: ['FanRequest'],
    }),

    updateFanRequest: builder.mutation<
      FanRequest,
      { id: number | string; values: Partial<FanRequestParams> }
    >({
      query: ({ id, values }) => ({
        url: `requests/${id}`,
        method: 'PUT',
        body: {
          ...values,
          expiresAt: !!values.expiresAt ? values.expiresAt / 1000 : null,
        },
      }),
      transformResponse: camelize,
      invalidatesTags: ['FanRequest'],
    }),

    deleteFanRequest: builder.mutation<FanRequest, { id: number }>({
      query: ({ id }) => ({ url: `requests/${id}`, method: 'DELETE' }),
      transformResponse: camelize,
      invalidatesTags: ['FanRequest'],
    }),

    checkUrlSlug: builder.query<
      { taken: boolean },
      { id?: number; value: string }
    >({
      query: ({ value, id }) => ({
        url: `url-slugs/check/${value}?id=${id || 0}`,
        method: 'GET',
      }),
    }),
  }),
});

export default extendedApi;

export const {
  useFetchFanRequestQuery,
  useFetchFanRequestsQuery,
  useLazyFetchFanRequestQuery,
  useLazyFetchFanRequestsQuery,
  useCreateFanRequestMutation,
  useUpdateFanRequestMutation,
  useDeleteFanRequestMutation,
  useCheckUrlSlugQuery,
  useLazyCheckUrlSlugQuery,
} = extendedApi;
