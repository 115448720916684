import { useFetchEstimateQuery } from 'api/estimates';
import LoadingPage from 'components/LoadingPage';
import NotFoundPage from 'components/NotFoundPage';
import routes from 'helpers/routes';
import { ReactComponent as FanLogo } from 'images/fan-logo.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { selectCurrentUser, selectSemiLoggedInUser } from 'redux/reducers/auth';
import { useAppSelector } from 'redux/store';
import MfaLogIn from '../MfaLogIn';
import { SignUpTrialFeatures, SignUpForm, SignUpLogInForm } from './components';
import SignUpPayment from './screens/SignUpPayment';

export default function SignUp() {
  const currentUser = useAppSelector(selectCurrentUser);
  const semiLoggedInUser = useAppSelector(selectSemiLoggedInUser);

  const history = useHistory();
  const { planId } = useParams<{ planId: string }>();
  const { data: estimate, isLoading, error } = useFetchEstimateQuery(planId);

  const { t } = useTranslation();
  const [userType, setUserType] = useState<'new' | 'existing'>('new');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');

  return error ? (
    <NotFoundPage />
  ) : isLoading ? (
    <LoadingPage />
  ) : !estimate ? null : currentUser ? (
    <SignUpPayment estimate={estimate} company={company} />
  ) : semiLoggedInUser ? (
    <MfaLogIn semiLoggedInUser={semiLoggedInUser} />
  ) : (
    <div className="h-screen w-screen flex relative overflow-hidden">
      <div className="absolute p-3 top-0 left-0 z-50">
        <FanLogo className="text-dark tablet:text-white w-15 tablet:w-full" />
      </div>
      <div className="hidden tablet:block absolute -top-24 desktop:-top-36 right-16 desktop:right-12">
        <div className="w-30 h-30 desktop:w-45 desktop:h-45 bg-socialieBlue rounded-full" />
      </div>
      <div className="absolute top-0 right-0 px-2 tablet:px-4 py-3 tablet:py-5 flex items-center space-x-1 z-40">
        <button
          className="px-1.5 h-4 hidden tablet:flex items-center bg-white hover:bg-grey8  transition-all duration-200 ease-in-out rounded-lg border-2 border-grey1 border-solid"
          onClick={() =>
            setUserType((userType) => (userType === 'new' ? 'existing' : 'new'))
          }
        >
          <span className="text-14 leading-20 text-dark font-bold">
            {userType === 'new'
              ? t('onboarding.signUp.main.button.socialieProUser')
              : t('onboarding.signUp.main.button.newUser')}
          </span>
        </button>

        <button
          className="btn btn--socialie btn--small btn--inlineBlock"
          onClick={() => history.push(routes.auth.logIn)}
        >
          {t('global.signIn')}
        </button>
      </div>

      <SignUpTrialFeatures />

      <div className="px-3 flex-1 flex flex-col items-center justify-center bg-white">
        <div className="max-w-58 w-full relative">
          {userType === 'new' ? (
            <SignUpForm
              onChangeEmail={setEmail}
              onChangeCompany={setCompany}
              onSignIn={() => setUserType('existing')}
            />
          ) : (
            <SignUpLogInForm
              email={email}
              onSignUp={() => setUserType('new')}
            />
          )}
        </div>
      </div>
    </div>
  );
}
