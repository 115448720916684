import React from 'react';
import { useTranslation } from 'react-i18next';
import Form from './Form';

interface Props {
  loadedCount: number;
  totalCount?: number;
  loading?: boolean;
  msgPrefix: string;
  selectedCount?: number;
  selectAll?: () => void;
  selectNone?: () => void;
}

export default function RecordCount(props: Props) {
  const {
    loadedCount,
    totalCount,
    loading,
    msgPrefix,
    selectedCount,
    selectAll,
    selectNone,
  } = props;

  const { t } = useTranslation();

  return (
    <div className="flex items-center text-14">
      {selectAll && selectNone && (
        <div className="mr-1">
          <Form.Checkbox
            checkedStyle={selectedCount === totalCount ? 'checkmark' : 'line'}
            checked={!!selectedCount}
            onChange={() => (!!selectedCount ? selectNone() : selectAll())}
          />
        </div>
      )}

      {loading ? (
        <span className="bump-up-1">{t(`${msgPrefix}.loading`)}</span>
      ) : !!selectedCount ? (
        <>
          <div className="text-dark bump-up-1">
            {t(`${msgPrefix}.selectedCount`, {
              count: totalCount,
              selectedCount,
            })}
          </div>
        </>
      ) : !!totalCount ? (
        <>
          <div className="text-dark  bump-up-1">
            {t(`${msgPrefix}.count`, { count: totalCount, loadedCount })}
          </div>
        </>
      ) : null}
    </div>
  );
}
