import api from './index';
import camelize from 'camelize';
import FanAccount from 'types/FanAccount';
import FanAccountParams from 'types/FanAccountParams';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateFanAccount: builder.mutation<FanAccount, Partial<FanAccountParams>>({
      query: (params) => ({
        url: 'account',
        method: 'PUT',
        body: params,
      }),
      transformResponse: camelize,
    }),

    switchFanAccount: builder.mutation<FanAccount, { id: number }>({
      query: ({ id }) => ({
        url: 'users/switch-account',
        method: 'PUT',
        body: { id },
      }),
      transformResponse: camelize,
    }),
  }),
});

export default extendedApi;

export const { useUpdateFanAccountMutation, useSwitchFanAccountMutation } =
  extendedApi;
