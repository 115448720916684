import cx from 'classnames';
import React from 'react';

interface Props {
  width: number;
  height: number;
  isSelected?: boolean;
  children?: React.ReactNode;
}

const FanSubmissionThumbnailContainer = React.memo((props: Props) => {
  const { width, height, isSelected, children } = props;

  let aspectRatio = height / width;

  // Crop pictures that are wider than 16:9 or taller than 9:16
  if (aspectRatio < 0.5625) aspectRatio = 0.5625;
  if (aspectRatio > 1.777777778) aspectRatio = 1.777777778;
  const reverseAspectRatio = 1 / aspectRatio;

  const styles = {
    '--reverse-aspect-ratio': reverseAspectRatio,
    '--aspect-ratio-percent': `${aspectRatio * 100}%`,
  } as React.CSSProperties;

  return (
    <div
      style={styles}
      className={cx('group fan-submission-list__thumbnail', {
        'fan-submission-list__thumbnail--selected': isSelected,
      })}
    >
      {children}
    </div>
  );
});

export default FanSubmissionThumbnailContainer;
