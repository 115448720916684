import routes from 'helpers/routes';
import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import {
  SettingsBrand,
  SettingsProfile,
  SettingsSubscription,
  SettingsDomains,
  SettingsUsers,
} from './screens';
import { SettingsNav } from './components';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import usePlan from 'hooks/usePlan';

export default function Settings() {
  const { t } = useTranslation();
  const plan = usePlan();

  return (
    <>
      <Helmet title={t('settings.pageTitle')} />

      <SettingsNav />

      <div className="pl-30 w-full bg-white with-topbar">
        {!plan.isActive ? (
          <Switch>
            <Route path={routes.settings.subscription}>
              <SettingsSubscription />
            </Route>
            <Redirect to={routes.settings.subscription} />
          </Switch>
        ) : (
          <Switch>
            <Route path={routes.settings.brand}>
              <SettingsBrand />
            </Route>
            <Route path={routes.settings.users}>
              <SettingsUsers />
            </Route>

            <Route path={routes.settings.subscription}>
              <SettingsSubscription />
            </Route>

            <Route path={routes.settings.profile}>
              <SettingsProfile />
            </Route>

            <Route path={routes.settings.domains.root}>
              <SettingsDomains />
            </Route>

            <Redirect to={routes.settings.profile} />
          </Switch>
        )}
      </div>
    </>
  );
}
