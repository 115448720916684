import formatBytes from 'helpers/formatBytes';
import usePlan from 'hooks/usePlan';
import { useTranslation, Trans } from 'react-i18next';
import Pill from './Pill';
import ProgressBar from './ProgressBar';

interface Props {
  children: React.ReactNode;
}

export default function Usage(props: Props) {
  return <>{props.children}</>;
}

Usage.Submissions = Submissions;
Usage.Storage = Storage;
Usage.Requests = Requests;
Usage.UsersPill = UsersPill;
Usage.UsersBar = UsersBar;

function Submissions() {
  const plan = usePlan();
  const { t } = useTranslation();
  const formatter = new Intl.NumberFormat('en-US');

  if (!plan.maxUploadsPerTerm) return null;

  return (
    <>
      <ProgressBar
        percent={(plan.usage.submissionCount / plan.maxUploadsPerTerm) * 100}
        redWhenFull
      />
      <div className="mt-0.25 text-12 leading-16">
        {t('usage.submissions', {
          used: formatter.format(plan.usage.submissionCount),
          allowed: formatter.format(plan.maxUploadsPerTerm),
        })}
      </div>
    </>
  );
}

function Storage() {
  const plan = usePlan();
  const { t } = useTranslation();

  if (!plan.maxStorageInGb) return null;

  const maxStorageInBytes = plan.maxStorageInGb * 1024 * 1024 * 1024;

  return (
    <>
      <ProgressBar
        percent={(plan.usage.storageUsed / maxStorageInBytes) * 100}
        redWhenFull
      />
      <div className="mt-0.25 text-12 leading-16">
        {t('usage.storage', {
          used: formatBytes(plan.usage.storageUsed),
          allowed: formatBytes(maxStorageInBytes),
        })}
      </div>
    </>
  );
}

interface RequestsProps {
  used?: number;
}

function Requests(props: RequestsProps) {
  const plan = usePlan();
  if (!plan.maxRequests) return null;

  return (
    <Pill size="small">
      <Trans
        i18nKey="usage.requests"
        components={{ normal: <span className="font-normal" /> }}
        values={{
          used: props.used || plan.usage.requestCount,
          count: plan.maxRequests,
        }}
      />
    </Pill>
  );
}

interface UsersPillProps {
  size?: React.ComponentProps<typeof Pill>['size'];
}

function UsersPill({ size }: UsersPillProps) {
  const plan = usePlan();
  if (!plan.maxUsers) return null;

  return (
    <Pill size={size || 'small'}>
      <Trans
        i18nKey="usage.users"
        components={{ normal: <span className="font-normal" /> }}
        values={{
          used: plan.usage.userCount,
          count: plan.maxUsers,
        }}
      />
    </Pill>
  );
}

interface UsersBarProps {
  used?: number;
}

function UsersBar(props: UsersBarProps) {
  const plan = usePlan();

  if (!plan.maxUsers) return null;
  const used = props.used || plan.usage.userCount;
  return (
    <>
      <ProgressBar percent={(used / plan.maxUsers) * 100} />
      <div className="mt-0.25 text-12 leading-16">
        <Trans
          i18nKey="usage.users"
          components={{ normal: <span /> }}
          values={{ used, count: plan.maxUsers }}
        />
      </div>
    </>
  );
}
