import api from './index';
import camelize from 'camelize';
import Plan from 'types/Plan';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchPlan: builder.query<Plan, void>({
      query: () => ({ url: 'plan' }),
      transformResponse: camelize,
      providesTags: ['FanPlan'],
    }),
  }),
});

export default extendedApi;

export const { useFetchPlanQuery } = extendedApi;
