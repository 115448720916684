import { IconTrash } from 'icons';
import React from 'react';
import FanSubmission from 'types/FanSubmission';
import { useState } from 'react';
import FanSubmissionDeleteModal from 'components/FanSubmissionDeleteModal';
import FanSubmissionRating from 'components/FanSubmissionRating';
import FanRequest from 'types/FanRequest';

interface Props {
  fanSubmission: FanSubmission;
  fanRequest: FanRequest;
}

export default function FanSubmissionThumbnailActions(props: Props) {
  const { fanSubmission, fanRequest } = props;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const iconClassName = 'block w-full h-full text-white';

  return (
    <>
      <div className="flex items-center space-x-1.5 absolute top-1 right-1 z-10">
        <button
          className="w-3 h-3 hidden group-hover:block"
          onClick={(e) => {
            setIsDeleteModalOpen(true);
            e.stopPropagation();
          }}
        >
          <IconTrash className={iconClassName} />
        </button>

        <FanSubmissionRating fanSubmission={fanSubmission} theme="thumbnail" />
      </div>

      <FanSubmissionDeleteModal
        fanRequest={fanRequest}
        fanSubmissions={[fanSubmission]}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        isOpen={isDeleteModalOpen}
      />
    </>
  );
}
