import PhoneNumber from 'awesome-phonenumber';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function isValidContactValue(
  contactValue: string,
  type?: 'email' | 'phone'
) {
  const isValidEmail = emailRegex.test(contactValue);
  const isValidPhone = isValidPhoneNumber(contactValue);
  if (!type) return isValidEmail || isValidPhone;
  if (type === 'email') return isValidEmail;
  if (type === 'phone') return isValidPhone;
}

function isValidPhoneNumber(phoneNumber: string) {
  if (new PhoneNumber(phoneNumber).isValid()) return true;

  // Try adding the + for them
  if (new PhoneNumber(`+${phoneNumber}`).isValid()) return true;

  // 10-digit North American number support
  return new PhoneNumber(`+1${phoneNumber}`).isValid();
}
