import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SemiLoggedInUser from 'types/SemiLoggedInUser';
import AuthPage from 'components/AuthPage';
import Form from 'components/Form';
import { useResendSmsCodeMutation, useValidateOtpMutation } from 'api/mfa';
import cx from 'classnames';
import RecoveryCodeLogIn from './RecoveryCodeLogIn';

interface Props {
  semiLoggedInUser: SemiLoggedInUser;
}

export default function MfaLogIn({ semiLoggedInUser }: Props) {
  const { mfaProtocol, mfaPhone, tempToken } = semiLoggedInUser;
  const [validateOtp, { data, isLoading, error }] = useValidateOtpMutation();
  const [isUsingRecoveryCode, setIsUsingRecoveryCode] = useState(false);

  const { t } = useTranslation();
  const [code, setCode] = useState('');

  if (isUsingRecoveryCode) {
    return (
      <RecoveryCodeLogIn
        semiLoggedInUser={semiLoggedInUser}
        onCancel={() => setIsUsingRecoveryCode(false)}
      />
    );
  }

  const handleSubmit = async () => {
    validateOtp({ mfaProtocol, tempToken, token: code });
  };

  const hasError = !!(data && 'errors' in data) || !!error;

  return (
    <AuthPage
      heading={t('auth.logIn.heading')}
      description={
        <div className="text-dark leading-tight mb-1">
          <Trans
            i18nKey={`auth.mfaLogIn.label.${mfaProtocol}`}
            values={{
              value:
                mfaProtocol === 'sms'
                  ? mfaPhone?.slice(-2)
                  : t('auth.mfaLogIn.label.authenticatorApp'),
            }}
            components={{ bold: <strong /> }}
          />
        </div>
      }
    >
      <Form onSubmit={handleSubmit}>
        <Form.TextInput
          name="code"
          value={code}
          label={t('auth.mfaLogin.label')}
          onChange={(e) => setCode(e.target.value)}
          placeholder={t('auth.mfaLogIn.placeholder')}
          errors={hasError ? t('auth.mfaLogIn.invalidCode') : null}
        />
        <div className="mb-2 flex items-center justify-end">
          <ResendButton semiLoggedInUser={semiLoggedInUser} />
        </div>

        <button
          type="submit"
          className="btn btn--socialie mb-3"
          disabled={isLoading || !code}
          data-loading={isLoading}
        >
          {t('auth.mfaLogIn.submit')}
        </button>
      </Form>

      <div className="flex items-center justify-center">
        <button
          onClick={() => setIsUsingRecoveryCode(true)}
          className="text-socialiePink hover:underline"
        >
          {t('auth.mfaLogIn.useRecoveryCode')}
        </button>
      </div>
    </AuthPage>
  );
}

function ResendButton({ semiLoggedInUser }: Props) {
  const [resendSmsCode, { data, isLoading }] = useResendSmsCodeMutation();
  const { t } = useTranslation();

  const handleResend = () => {
    const { tempToken, mfaProtocol } = semiLoggedInUser;
    resendSmsCode({ tempToken, mfaProtocol });
  };

  const hasResentSms = data?.success;

  return (
    <button
      className="mb-1 cursor-pointer"
      onClick={handleResend}
      type="button"
    >
      <div className="flex items-center">
        <span
          className={cx('text-14 leading-20', {
            'text-socialiePink hover:underline': !hasResentSms && !isLoading,
            'text-grey6': isLoading,
            'text-green': hasResentSms,
          })}
        >
          {isLoading
            ? t('auth.mfaLogIn.sendingCode')
            : hasResentSms
            ? t('auth.mfaLogIn.resentCode')
            : t('auth.mfaLogIn.resendCode')}
        </span>
      </div>
    </button>
  );
}
