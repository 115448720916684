import Domain from 'types/Domain';
import SettingsDomainsDelete from './SettingsDomainsDelete';
import SettingsDomainsDNS from './SettingsDomainsDNS';
import SettingsDomainsSSL from './SettingsDomainsSSL';

interface Props {
  domain: Domain;
}

export default function SettingsDomainsRow({ domain }: Props) {
  return (
    <>
      <div className="flex items-center border-b-solid border-b border-b-lightBorder py-1.5 last:border-b-0">
        <div>
          <a
            className="font-bold text-dark hover:underline"
            href={`https://${domain.domain}`}
            target="_"
            rel="nofollow"
          >
            https://{domain.domain}
          </a>
        </div>

        <div className="flex items-center ml-auto space-x-2">
          {domain.validationValue && domain.validationCname && (
            <SettingsDomainsSSL domain={domain} />
          )}

          <SettingsDomainsDNS domain={domain} />

          <SettingsDomainsDelete domain={domain} />
        </div>
      </div>
    </>
  );
}
