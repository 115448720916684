import api from './index';
import camelize from 'camelize';
import FetchCurrentUserResponse from 'types/FetchCurrentUserResponse';
import SubscriptionParams from 'types/SubscriptionParams';
import Subscription from 'types/Subscription';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createSubscription: builder.mutation<
      FetchCurrentUserResponse,
      SubscriptionParams
    >({
      query: (params) => ({
        url: 'subscription',
        method: 'POST',
        body: params,
      }),
      transformResponse: camelize,
      invalidatesTags: ['FanPlan'],
    }),

    fetchSubscription: builder.query<Subscription, void>({
      query: () => ({ url: 'subscription' }),
      transformResponse: camelize,
      providesTags: ['Subscription'],
    }),

    cancelSubscription: builder.mutation<void, void>({
      query: () => ({ url: 'subscription/cancel', method: 'POST' }),
      invalidatesTags: ['Subscription', 'FanPlan'],
    }),

    reactivateSubscription: builder.mutation<void, void>({
      query: () => ({ url: 'subscription/reactivate', method: 'POST' }),
      invalidatesTags: ['Subscription', 'FanPlan'],
    }),

    resumeSubscriptionTrial: builder.mutation<void, void>({
      query: () => ({ url: 'subscription/resume-trial', method: 'POST' }),
      invalidatesTags: ['Subscription', 'FanPlan'],
    }),
  }),
});

export default extendedApi;

export const {
  useCreateSubscriptionMutation,
  useFetchSubscriptionQuery,
  useCancelSubscriptionMutation,
  useReactivateSubscriptionMutation,
  useResumeSubscriptionTrialMutation,
} = extendedApi;
