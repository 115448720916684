import cx from 'classnames';

interface Props {
  percent: number;
  redWhenFull?: boolean;
}

export default function ProgressBar(props: Props) {
  const { percent, redWhenFull } = props;

  return (
    <div className="relative rounded-full overflow-hidden bg-grey7 h-1 w-full">
      <div
        className={cx(
          'absolute top-0 left-0 h-full max-w-full',
          percent < 100 || !redWhenFull ? 'bg-brightBlue' : 'bg-error'
        )}
        style={{ width: `${percent}%` }}
      />
    </div>
  );
}
