import CopyButton from './CopyButton';

interface Props {
  url: string;
}

export default function ShareUrl({ url }: Props) {
  return (
    <div className="relative inline-flex items-center h-5 px-1.5 rounded-lg bg-greyBg">
      <a
        href={url}
        target="_blank"
        className="text-dark font-bold hover:underline max-w-40 truncate"
        title={url}
        rel="noreferrer"
      >
        {url.split('//')[1]}
      </a>
      <div className="ml-0.5 w-2 h-2">
        <CopyButton value={url} className="hover:scale-110 transition-fast" />
      </div>
    </div>
  );
}
