const routes = {
  auth: {
    logIn: '/log-in',
    signUp: '/sign-up/:planId',
    logInRecovery: '/log-in/recovery-code',
    magicSignIn: '/magic-sign-in/:token',
  },
  fanRequestBuilder: '/builder/:id',
  index: '/',
  requests: {
    index: '/requests/:type?',
    show: '/requests/:type/:id',
    edit: '/requests/:type/:id/edit',
    build: '/requests/:type/:id/build',
    fanSubmissionShow: '/requests/:type/:id/submissions/:fanSubmissionId',
  },
  invitation: '/invites/:code',
  settings: {
    brand: '/settings/brand',
    profile: '/settings/my-profile',
    root: '/settings',
    subscription: '/settings/subscription',
    users: '/settings/users',
    domains: {
      root: '/settings/domains',
      dns: '/settings/domains/:id/dns',
      ssl: '/settings/domains/:id/ssl',
      new: '/settings/domains/new',
    },
  },
};

export default routes;
