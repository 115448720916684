import { IconErrorPage, Icon404 } from 'icons';
import { useTranslation } from 'react-i18next';

interface Props {
  status?: number;
}

export default function FanRequestError({ status = 500 }: Props) {
  const { t } = useTranslation();
  const Icon = status === 404 ? Icon404 : IconErrorPage;

  return (
    <div className="w-full flex-1 flex-v-center">
      <div className="mx-auto text-center px-2">
        <div className="mx-auto w-12 h-12 mb-4.5">
          <Icon className="w-full h-full" />
        </div>

        <h4 className="h4 mb-2 px-2">
          {t(`fanRequestError.${status === 404 ? '404' : '500'}`)}
        </h4>

        <a href="/" className="btn btn--socialie btn--inlineBlock">
          {t('fanRequestError.dashboard')}
        </a>
      </div>
    </div>
  );
}
