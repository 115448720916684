import { useEffect, useRef, useState } from 'react';
import FanRequestParams from 'types/FanRequestParams';
import Form from 'components/Form';
import { useTranslation } from 'react-i18next';
import { FanRequestPreviewDetails, FanRequestPreviewHome } from './components';
import FanAccount from 'types/FanAccount';

export interface FanRequestPreviewProps {
  dataUrls: { coverPhoto: string | null };
  values: Pick<
    FanRequestParams,
    | 'mediaType'
    | 'expiresAt'
    | 'videoDuration'
    | 'subject'
    | 'instructions'
    | 'backgroundColor'
    | 'backgroundBlurAmount'
    | 'backgroundOverlayOpacity'
    | 'orientation'
    | 'introHeader'
    | 'introDescription'
    | 'introButtonText'
  >;
  fanAccount: Pick<
    FanAccount,
    | 'backgroundColorHome'
    | 'logoUrl'
    | 'headlineTextColorHome'
    | 'name'
    | 'bodyTextColorHome'
    | 'cardBorderRadius'
    | 'headlineTextColor'
    | 'bodyTextColor'
    | 'buttonBackgroundColor'
    | 'buttonBorderRadius'
    | 'buttonTextColor'
  >;
}

export default function FanRequestPreview(props: FanRequestPreviewProps) {
  const { values, fanAccount, dataUrls } = props;
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [screen, setScreen] = useState<'home' | 'modal' | 'instructions'>(
    'home'
  );

  useEffect(() => {
    function setFixedPosition() {
      if (!wrapperRef.current) return;
      const rect = wrapperRef.current.getBoundingClientRect();
      setPosition({ top: rect.top + window.pageYOffset, left: rect.left });
    }
    setFixedPosition();

    window.addEventListener('resize', setFixedPosition);

    return () => {
      window.removeEventListener('resize', setFixedPosition);
    };
  }, []);

  return (
    <div className="mx-auto w-44 max-w-full" ref={wrapperRef}>
      <div className="fixed w-44" style={position}>
        <div className="flex justify-center mb-3">
          <Form.ToggleGroup variant="white">
            {(['home', 'modal', 'instructions'] as typeof screen[]).map(
              (type) => (
                <Form.ToggleGroup.Option
                  key={type}
                  isSelected={screen === type}
                  onSelect={() => setScreen(type)}
                  title={t(`fanRequestBuilder.preview.screen.${type}`)}
                />
              )
            )}
          </Form.ToggleGroup>
        </div>

        <div className="bg-white w-full filter drop-shadow-xl h-70 overflow-y-hidden">
          {screen === 'home' ? (
            <FanRequestPreviewHome
              values={values}
              fanAccount={fanAccount}
              coverPhotoUrl={dataUrls.coverPhoto}
            />
          ) : (
            <FanRequestPreviewDetails
              values={values}
              fanAccount={fanAccount}
              coverPhotoUrl={dataUrls.coverPhoto}
              showModal={screen === 'modal'}
            />
          )}
        </div>
      </div>
    </div>
  );
}
