import Btn from 'components/Btn';
import Modal from 'components/Modal';
import { IconArrowRight } from 'icons';
import { useTranslation, Trans } from 'react-i18next';
import confetti1 from 'images/confetti1.svg';
import confetti2 from 'images/confetti2.svg';

interface Props {
  onContinue: () => void;
}

export default function OnboardingIntro(props: Props) {
  const { onContinue } = props;
  const { t } = useTranslation();

  return (
    <Modal
      isOpen
      onRequestClose={() => {}}
      theme="onboarding"
      containerClassNames="!w-60"
      renderHeading={
        <div className="h1 px-5">{t('onboarding.intro.heading')}</div>
      }
      renderActions={
        <Btn
          variant="inlineBlock"
          rightIcon={IconArrowRight}
          onClick={onContinue}
        >
          {t('global.continue')}
        </Btn>
      }
    >
      <>
        <div className="px-3 text-dark text-14">
          <Trans
            i18nKey="onboarding.intro.description"
            components={{
              p: <p className="mb-2 last:mb-0" />,
              contactLink: (
                /* eslint-disable-next-line jsx-a11y/anchor-has-content */
                <a
                  href="mailto:support@socialie.com"
                  className="text-socialiePink hover:underline"
                />
              ),
            }}
          />
        </div>

        <img
          src={confetti1}
          alt="Confetti!"
          className="absolute -top-4 right-2 w-9 h-9"
        />
        <img
          src={confetti2}
          alt="More Confetti!"
          className="absolute -top-3 left-2 w-8 h-8 rotate-[100deg]"
        />
      </>
    </Modal>
  );
}
