import { useLogOutMutation } from 'api/auth';
import { Link } from 'react-router-dom';
import User from 'types/User';
import Avatar from 'components/Avatar';
import FlyoutMenu from '../../FlyoutMenu';
import { useTranslation } from 'react-i18next';
import routes from 'helpers/routes';
import { IconGear, IconSignOut } from 'icons';

interface Props {
  currentUser: User;
}

export default function UserMenu({ currentUser }: Props) {
  const [logOut] = useLogOutMutation();
  const { t } = useTranslation();
  const handleLogOut = async () => {
    await logOut();
    window.location.reload();
  };

  return (
    <FlyoutMenu
      placement="bottom-end"
      offset={[0, 8]}
      renderButton={(onClick, isOpen, ref) => (
        <button className="w-4.5 h-4.5" onClick={onClick} ref={ref}>
          <Avatar src={currentUser.avatarUrl} name={currentUser.name} />
        </button>
      )}
    >
      <Link
        to={routes.settings.root}
        className="flex items-center space-x-0.75"
      >
        <IconGear className="w-2 h-2 text-grey6" />
        <span className="mb-px">{t('globalTopBar.userMenu.settings')}</span>
      </Link>

      <button onClick={handleLogOut}>
        <span className="flex items-center space-x-0.75">
          <IconSignOut className="w-2 h-2 text-grey6" />
          <span className="mb-px">{t('globalTopBar.userMenu.logOut')}</span>
        </span>
      </button>
    </FlyoutMenu>
  );
}
