import { IconInfo } from 'icons';
import Tooltip from 'components/Tooltip';
import { useTranslation } from 'react-i18next';

export default function SettingsUsersPermissionsTooltip() {
  const { t } = useTranslation();
  return (
    <Tooltip
      theme="socialieDark"
      content={
        <ul className="list-disc list-outside pl-3 space-y-0.5 max-w-28">
          <li>{t('settings.users.permissionsTooltip.owner')}</li>
          <li>{t('settings.users.permissionsTooltip.member')}</li>
        </ul>
      }
    >
      <IconInfo className="w-2 h-2 text-light" />
    </Tooltip>
  );
}
