import { useTranslation } from 'react-i18next';
import User from 'types/User';
import Form from 'components/Form';
import useUpdateProfile from 'hooks/useUpdateProfile';
import useFlashMessages from 'hooks/useFlashMessages';
import SettingsProfileSection from './SettingsProfileSection';
import { IconPerson } from 'icons';

interface Props {
  currentUser: User;
}

export default function SettingsProfileAvatar(props: Props) {
  const { currentUser } = props;
  const { t } = useTranslation();
  const { addFlashMessage } = useFlashMessages();

  const [updateProfile] = useUpdateProfile({
    onSuccess: () => {
      addFlashMessage(t('settings.profile.avatar.success'));
    },
  });

  const handleSubmit = (avatar: string | null) => {
    updateProfile({ avatar });
  };

  return (
    <SettingsProfileSection>
      <Form.FileInput
        aspectRatio={1}
        extensions={['jpg', 'jpeg', 'png']}
        placeholderIcon={IconPerson}
        borderRadius={9999}
        attachment={{
          src: currentUser.avatarUrl,
          contentType: currentUser.avatarContentType,
        }}
        onChange={(value) => handleSubmit(value)}
      />
    </SettingsProfileSection>
  );
}
