import ReactSelect from 'react-select';
import cx from 'classnames';
import { NamedProps } from 'react-select/src/Select';
import Field from './Field';

interface Props extends NamedProps {
  label?: React.ReactNode;
  errors?: string | string[] | null;
  required?: boolean;
  name?: string;
  description?: string;
  skipField?: boolean;
  size?: 'small';
}

export default function Select(props: Props) {
  const {
    label,
    errors,
    description,
    name,
    required,
    skipField,
    size,
    ...selectProps
  } = props;
  const renderSelect = (
    <ReactSelect
      {...selectProps}
      classNamePrefix="form-select"
      className={cx({
        'form-select--small': size === 'small',
        'form-select--errors': errors?.length,
      })}
      styles={{
        // This element is not able to be styled with CSS because react-select
        // doesn't give it a className
        input: (provided, state) => ({
          ...provided,
          height: size === 'small' ? '30px' : provided.height,
          margin: size === 'small' ? 0 : provided.margin,
          paddingTop: size === 'small' ? 0 : provided.paddingTop,
        }),
      }}
    />
  );

  if (skipField) return renderSelect;

  return (
    <Field
      label={label}
      description={description}
      errors={errors}
      required={required}
      name={name}
    >
      {renderSelect}
    </Field>
  );
}
