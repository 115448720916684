interface Props {
  heading: React.ReactNode;
  children: React.ReactNode;
}

export default function SettingsSubscriptionSection(props: Props) {
  const { heading, children } = props;

  return (
    <div className="pb-4 mb-4 border-b-default last:border-b-0">
      <h6 className="h6 mb-0.5">{heading}</h6>
      {children}
    </div>
  );
}
