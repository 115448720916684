import CollapsibleSection from 'components/CollapsibleSection';
import formatDuration from 'helpers/formatDuration';
import { useTranslation } from 'react-i18next';
import FanSubmission from 'types/FanSubmission';

interface Props {
  fanSubmission: FanSubmission;
}

export default function FanSubmissionSidebarMetadata(props: Props) {
  const { fanSubmission } = props;
  const { t } = useTranslation();

  return (
    <CollapsibleSection
      title={t('fanRequestShow.submissions.sidebar.metadata')}
    >
      <div className="flex items-center justify-between text-12 text-dark">
        <div className="flex-1 mr-2 font-semibold">
          {t('fanRequestShow.submissions.sidebar.dimensions')}
        </div>
        <div>
          {fanSubmission.width}x{fanSubmission.height}
        </div>
      </div>

      {!!fanSubmission.videoDuration && (
        <div className="flex items-center justify-between text-12 text-dark">
          <div className="flex-1 mr-2 font-semibold">
            {t('fanRequestShow.submissions.sidebar.duration')}
          </div>
          <div>{formatDuration(fanSubmission.videoDuration)}</div>
        </div>
      )}

      <div className="flex items-center justify-between text-12 text-dark">
        <div className="flex-1 mr-2 font-semibold">
          {t('fanRequestShow.submissions.sidebar.contentType')}
        </div>
        <div>{fanSubmission.contentType}</div>
      </div>
    </CollapsibleSection>
  );
}
