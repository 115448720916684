import React, { forwardRef } from 'react';
import cx from 'classnames';
import Field from './Field';
import { useState } from 'react';
import InputContainer from './InputContainer';
import { useRef } from 'react';
import mergeRefs from 'react-merge-refs';
import { useAppSelector } from 'redux/store';

interface Props
  extends React.ComponentProps<'input'>,
    Omit<
      React.ComponentProps<typeof InputContainer>,
      'multiline' | 'onClickIcon'
    > {
  label?: React.ReactNode;
  description?: string;
  skipField?: boolean;
}

const TextInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    label,
    required,
    name,
    errors,
    description,
    prefix,
    leftIcon,
    rightIcon,
    leftIconClassNames,
    rightIconClassNames,
    onClear,
    skipField,
    variant,
    value,
    type = 'text',
    ...inputProps
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const passwordsVisible = useAppSelector((state) => state.ui.passwordsVisible);
  const currentType = type === 'password' && passwordsVisible ? 'text' : type;

  const renderInput = () => (
    <InputContainer
      value={value}
      errors={errors}
      onClear={onClear}
      variant={variant}
      isFocused={isFocused}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      rightIconClassNames={rightIconClassNames}
      leftIconClassNames={leftIconClassNames}
      onClickIcon={() => inputRef.current?.click()}
      prefix={prefix}
      disabled={inputProps.disabled}
      maxLength={inputProps.maxLength}
      isPassword={type === 'password'}
    >
      <input
        ref={mergeRefs([ref, inputRef])}
        value={value}
        type={currentType}
        className={cx(
          'appearance-none w-full h-full font-sans bg-transparent text-dark outline-none focus:outline-none focus-visible:outline-none disabled:opacity-50',
          { 'text-center': variant === 'center' }
        )}
        {...inputProps}
        aria-required={required}
        name={name}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </InputContainer>
  );

  if (skipField) return renderInput();

  return (
    <Field
      label={label}
      name={name}
      errors={errors}
      description={description}
      required={required}
    >
      {renderInput()}
    </Field>
  );
});

export default TextInput;
