import api from './index';
import camelize from 'camelize';
import SubscriptionParams from 'types/SubscriptionParams';
import Subscription from 'types/Subscription';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createCard: builder.mutation<
      Subscription['card'],
      Pick<SubscriptionParams, 'token' | 'zip'>
    >({
      query: (params) => ({
        url: 'cards',
        method: 'POST',
        body: params,
      }),
      transformResponse: camelize,
      invalidatesTags: ['Subscription'],
    }),
  }),
});

export default extendedApi;

export const { useCreateCardMutation } = extendedApi;
