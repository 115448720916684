import Form from 'components/Form';
import { useTranslation } from 'react-i18next';

interface Props {
  value?: number | null;
  errors?: string[];
  onChange: (value: number | null) => void;
}

export default function MediaType({ value, onChange, errors }: Props) {
  const { t } = useTranslation();
  return (
    <Form.Field
      required
      errors={errors}
      label={t('fanRequestBuilder.form.videoDuration.label')}
    >
      <Form.ToggleGroup>
        {[15, 30, 60].map((duration) => (
          <Form.ToggleGroup.Option
            key={duration}
            isSelected={value === duration}
            onSelect={() => onChange(duration)}
            title={t(`fanRequestBuilder.form.videoDuration.${duration}`)}
          />
        ))}

        <Form.ToggleGroup.Option
          isSelected={value === 0}
          onSelect={() => onChange(0)}
          title={t('fanRequestBuilder.form.videoDuration.any')}
        />
      </Form.ToggleGroup>
    </Form.Field>
  );
}
