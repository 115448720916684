import { useLogOutMutation } from 'api/auth';
import Btn from 'components/Btn';
import Modal from 'components/Modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectCurrentUser } from 'redux/reducers/auth';
import { useAppSelector } from 'redux/store';

export default function SignUpPaymentUserMenu() {
  const user = useAppSelector(selectCurrentUser);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const [logOut, { isLoading }] = useLogOutMutation();
  const handleLogOut = async () => {
    await logOut();
    window.location.reload();
  };

  if (!user?.email) return null;

  return (
    <>
      <div className="fixed top-3 right-2 flex items-center text-12 bg-white rounded-full px-1 py-0.5">
        <div className="text-bodyText mr-0.5">{user.email}</div>
        <button
          className="text-dark underline"
          onClick={() => setIsModalOpen(true)}
        >
          {t('global.signOut')}
        </button>
      </div>

      <Modal
        renderHeading={t('onboarding.signUp.payment.signOut.heading')}
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      >
        {t('onboarding.signUp.payment.signOut.body')}
        <Modal.Actions>
          <Btn onClick={handleLogOut} isLoading={isLoading}>
            {t('global.signOut')}
          </Btn>

          <Btn color="grey" onClick={() => setIsModalOpen(false)}>
            {t('global.cancel')}
          </Btn>
        </Modal.Actions>
      </Modal>
    </>
  );
}
