import { useResendFanAccountUserInviteMutation } from 'api/fanAccountUser';
import Avatar from 'components/Avatar';
import formatDate from 'helpers/formatDate';
import useFlashMessages from 'hooks/useFlashMessages';
import { useTranslation } from 'react-i18next';
import FanAccountUser from 'types/FanAccountUser';
import User from 'types/User';
import SettingsUsersRemoveUser from './SettingsUsersRemoveUser';
import SettingsUsersRole from './SettingsUsersRole';

interface Props {
  fanAccountUser: FanAccountUser;
  currentUser: User;
}

export default function SettingsUsersActiveRow(props: Props) {
  const { fanAccountUser, currentUser } = props;
  const { id, user, pending, invitedAt } = fanAccountUser;
  const { t } = useTranslation();
  const { addFlashMessage } = useFlashMessages();
  const [resendInvite, { isLoading: isResending }] =
    useResendFanAccountUserInviteMutation();

  const { name, avatarUrl, email } = user;

  const handleResend = async () => {
    const result = await resendInvite({ id });

    if ('error' in result) {
      addFlashMessage(t('global.unexpectedError'), { isError: true });
    } else {
      addFlashMessage(t('settings.users.invite.resendSuccess'));
    }
  };

  return (
    <div
      key={fanAccountUser.id}
      className="py-2 flex flex-col border-0 border-b border-solid border-grey7"
    >
      <div className="flex items-center">
        <div className="flex-grow flex items-center space-x-1.5">
          <div className="w-5 h-5">
            <Avatar src={avatarUrl} name={name} />
          </div>
          <div className="flex flex-col">
            <span className="text-14 text-dark leading-20">
              {pending ? email : name}
            </span>
            <div className="flex items-center text-14 text-grey5 leading-20">
              {pending ? (
                <>
                  {!!invitedAt && (
                    <span className="mr-1">
                      {t('settings.users.invitedDate', {
                        date: formatDate(invitedAt, { time: false }),
                      })}
                    </span>
                  )}

                  {currentUser.role === 'owner' && (
                    <button
                      className="underline hover:text-grey4 disabled:opacity-50"
                      onClick={handleResend}
                      disabled={isResending}
                    >
                      {t('settings.users.invite.resend')}
                    </button>
                  )}
                </>
              ) : (
                email
              )}

              {currentUser.role === 'owner' &&
                fanAccountUser.user.id !== currentUser.id && (
                  <SettingsUsersRemoveUser fanAccountUser={fanAccountUser} />
                )}
            </div>
          </div>
        </div>
        {currentUser.role === 'owner'}
        <div className="flex-shrink-0 ">
          <div className="w-21">
            <SettingsUsersRole
              fanAccountUser={fanAccountUser}
              currentUser={currentUser}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
