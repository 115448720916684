import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'ui',
  initialState: { passwordsVisible: false },
  reducers: {
    togglePasswordsVisible: (state) => {
      state.passwordsVisible = !state.passwordsVisible;
    },
  },
});

export default reducer;

export const { togglePasswordsVisible } = actions;
