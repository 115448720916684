import { format } from 'date-fns';
import { useRef } from 'react';
import TextInput from './TextInput';
import { IconCaretUp, IconCaretDown } from 'icons';

interface Props {
  value: number;
  onChange: (timeStr: string) => void;
}

type Field = 'hour' | 'minute' | 'ampm';

const formatMinute = (val: number | string) => ('0' + val).slice(-2);

export default function TimeInputs({ value, onChange }: Props) {
  const timer = useRef<number | null>(null);
  const hour = format(value, 'h');
  const minute = format(value, 'mm');
  const ampm = format(value, 'aa');

  const handleChange = (field: Field, value: string) => {
    const nextValues = { hour, minute, ampm };
    const hourInt = parseInt(hour, 10);
    const minuteInt = parseInt(minute, 10);
    const canIncrement = { hour: hourInt < 12, minute: minuteInt < 59 };
    const canDecrement = { hour: hourInt > 1, minute: minuteInt > 0 };

    const operations = {
      hour: {
        up: canIncrement.hour ? hourInt + 1 : 1,
        down: canDecrement.hour ? hourInt - 1 : 12,
      },
      minute: {
        up: formatMinute(canIncrement.minute ? minuteInt + 1 : 0),
        down: formatMinute(canDecrement.minute ? minuteInt - 1 : 59),
      },
      ampm: {
        up: ampm === 'AM' ? 'PM' : 'AM',
        down: ampm === 'AM' ? 'PM' : 'AM',
      },
    };

    if (value === 'up' || value === 'down') {
      nextValues[field] = `${operations[field][value]}`;
    } else {
      nextValues[field] = value;
    }

    onChange(sanitizeValues(nextValues));
  };

  const sanitizeValues = (values: {
    hour: string;
    minute: string;
    ampm: string;
  }) => {
    const { hour, minute, ampm } = values;
    const nextValues = { hour, minute, ampm };
    const hourInt = parseInt(hour, 10);
    if (hourInt > 12 || hourInt < 1 || hour.match(/\D+/)) {
      nextValues.hour = '1';
    }

    if (minute.length > 2) nextValues.minute = nextValues.minute.slice(-2);
    if (minute.length < 2) nextValues.minute = formatMinute(nextValues.minute);

    const minuteInt = parseInt(nextValues.minute, 10);
    if (minuteInt > 59 || minuteInt < 0 || nextValues.minute.match(/\D+/)) {
      nextValues.minute = '00';
    }

    return `${nextValues.hour}:${nextValues.minute}${nextValues.ampm}`;
  };

  const hold = (field: Field, direction: 'up' | 'down') => {
    if (timer.current) window.clearTimeout(timer.current);

    handleChange(field, direction);
    timer.current = window.setTimeout(() => hold(field, direction), 150);
  };

  const stopHolding = () => timer.current && clearTimeout(timer.current);

  const renderArrow = (field: Field, direction: 'up' | 'down') => {
    const iconClassName = 'block w-full h-full text-dark';
    return (
      <button
        onMouseDown={() => hold(field, direction)}
        onMouseUp={stopHolding}
        className="block w-3 h-3 mx-auto last:mt-0.25"
      >
        {direction === 'up' ? (
          <IconCaretUp className={iconClassName} />
        ) : (
          <IconCaretDown className={iconClassName} />
        )}
      </button>
    );
  };

  return (
    <div className="flex items-center">
      <div className="w-6 mr-0.5">
        {renderArrow('hour', 'up')}
        <TextInput
          value={hour}
          skipField
          variant="center"
          onChange={(e) => handleChange('hour', e.target.value)}
        />
        {renderArrow('hour', 'down')}
      </div>

      <div className="mr-0.5">:</div>

      <div className="w-6 mr-0.5">
        {renderArrow('minute', 'up')}
        <TextInput
          value={minute}
          skipField
          variant="center"
          onChange={(e) => handleChange('minute', e.target.value)}
        />
        {renderArrow('minute', 'down')}
      </div>

      <div className="w-6">
        {renderArrow('ampm', 'up')}
        <TextInput
          value={ampm}
          variant="center"
          onClick={() => handleChange('ampm', 'up')}
          onChange={() => {}}
          skipField
        />
        {renderArrow('ampm', 'down')}
      </div>
    </div>
  );
}
