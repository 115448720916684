import { IconClose } from 'icons';
import cx from 'classnames';
import { createElement } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  color?: 'default' | 'blue' | 'yellow' | 'success' | 'red' | 'darkGrey';
  size?: 'normal' | 'small';
  children: React.ReactNode;
  icon?: typeof IconClose;
  onRemove?: () => void;
}

export default function Pill(props: Props) {
  const {
    color = 'default',
    size = 'normal',
    icon,
    children,
    onRemove,
  } = props;

  const { t } = useTranslation();

  return (
    <div
      className={cx(
        'inline-flex items-center rounded-full font-bold leading-none h-2.5 text-12 w-auto whitespace-nowrap',
        {
          'px-1': !onRemove,
          'pl-1 pr-0.75': !!onRemove,
          'tablet:h-4 tablet:text-14 tablet:px-2': size === 'normal',
          'border border-solid border-grey7 bg-white text-dark':
            color === 'default',
          'text-red bg-redBg': color === 'red',
          'text-blue bg-blueBg': color === 'blue',
          'text-yellow bg-yellowBg': color === 'yellow',
          'text-success bg-successBg': color === 'success',
          'text-white bg-grey1': color === 'darkGrey',
        }
      )}
    >
      {icon && createElement(icon, { className: 'block w-2 h-2 mr-1' })}
      <span className="bump-up-1">{children}</span>
      {onRemove && (
        <button
          type="button"
          className="w-2 h-2 p-0.25"
          onClick={onRemove}
          title={t('pill.remove')}
        >
          <IconClose className="ml-0.25 -mr-0.5 block w-full h-full" />
        </button>
      )}
    </div>
  );
}
