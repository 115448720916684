import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import authApi from 'api/auth';
import mfaApi from 'api/mfa';
import userApi from 'api/user';
import magicLinkApi from 'api/magicLinks';
import invitationApi from 'api/invitations';
import fanAccountApi from 'api/fanAccount';
import SemiLoggedInUser from 'types/SemiLoggedInUser';
import User from 'types/User';

interface AuthState {
  currentUser: User | null;
  semiLoggedInUser: SemiLoggedInUser | null;
}

const { reducer } = createSlice({
  name: 'auth',
  initialState: { currentUser: null, semiLoggedInUser: null } as AuthState,
  reducers: {},
  extraReducers: (builder) => {
    [
      authApi.endpoints.logIn.matchFulfilled,
      magicLinkApi.endpoints.validateMagicLink.matchFulfilled,
    ].forEach((endpoint) => {
      builder.addMatcher(endpoint, (state, { payload }) => {
        if ('error' in payload) return state;

        if ('tempToken' in payload.user) {
          state.semiLoggedInUser = payload.user;
          state.currentUser = null;
        } else {
          state.currentUser = payload.user;
          state.semiLoggedInUser = null;
        }
      });
    });

    [
      authApi.endpoints.fetchCurrentUser.matchFulfilled,
      invitationApi.endpoints.acceptInvitation.matchFulfilled,
      userApi.endpoints.signUpUser.matchFulfilled,
    ].forEach((match) => {
      builder.addMatcher(match, (state, { payload }) => {
        state.currentUser = payload.user;
      });
    });

    builder.addMatcher(
      mfaApi.endpoints.validateOtp.matchFulfilled,
      (state, { payload }) => {
        if (!('valid' in payload)) {
          state.currentUser = payload.user;
          state.semiLoggedInUser = null;
        }
      }
    );

    builder.addMatcher(
      mfaApi.endpoints.recoveryCodeLogIn.matchFulfilled,
      (state, { payload }) => {
        if (!('errors' in payload)) {
          state.currentUser = payload.user;
          state.semiLoggedInUser = null;
        }
      }
    );

    // Update current user after updating fan account
    builder.addMatcher(
      fanAccountApi.endpoints.updateFanAccount.matchFulfilled,
      (state, { payload }) => {
        if (!state.currentUser) return;
        state.currentUser.currentFanAccount = payload;
        state.currentUser.fanAccounts = state.currentUser.fanAccounts.map(
          (account) => (account.id === payload.id ? payload : account)
        );
      }
    );

    // Update current user after updating profile
    builder.addMatcher(
      userApi.endpoints.updateProfile.matchFulfilled,
      (state, { payload }) => {
        if (!state.currentUser) return;
        state.currentUser = payload.user;
      }
    );
  },
});

export default reducer;

export const selectCurrentUser = (state: RootState) => state.auth.currentUser;

export const selectSemiLoggedInUser = (state: RootState) =>
  state.auth.semiLoggedInUser;
