import React from 'react';
import cx from 'classnames';
import useFlashMessages from 'hooks/useFlashMessages';
import { AnimatePresence, motion } from 'framer-motion';
import { IconClose } from 'icons';

export default function FlashMessages() {
  const { flashMessages, removeFlashMessage } = useFlashMessages();

  return (
    <div
      className={cx('fixed bottom-0 left-0 p-2 z-flash', {
        'pointer-events-none': !flashMessages.length,
      })}
    >
      <AnimatePresence>
        {flashMessages.map((msg) => {
          const removeMsg = () => removeFlashMessage(msg.id);

          return (
            <motion.div
              key={msg.id}
              layout
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.2 }}
              className={cx(
                'relative p-2 pr-6 text-white shadow-xl w-48 rounded mb-1 last:mb-0',
                {
                  'bg-error': msg.isError,
                  'bg-green': !msg.isError,
                }
              )}
            >
              <span className="font-bold">{msg.text}</span>

              <button onClick={removeMsg} className="absolute top-2.75 right-2">
                <IconClose className="block w-2 h-2" />
              </button>
            </motion.div>
          );
        })}
      </AnimatePresence>
    </div>
  );
}
