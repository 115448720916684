import { SecondaryTopBar } from 'components/Layout/components';
import { useTranslation } from 'react-i18next';
interface Props {
  isLoading: boolean;
}

export default function SettingsBrandTopBar({ isLoading }: Props) {
  const { t } = useTranslation();

  return (
    <SecondaryTopBar
      heading={t('settings.brand.heading')}
      subHeading={t('settings.brand.subHeading')}
    >
      <button
        type="submit"
        disabled={isLoading}
        data-loading={isLoading}
        className="btn btn--socialie btn--inlineBlock"
      >
        {t('global.save')}
      </button>
    </SecondaryTopBar>
  );
}
