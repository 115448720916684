import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import formatDate from 'helpers/formatDate';
import FanRequest from 'types/FanRequest';
import EditExpirationModal from './EditExpirationModal';
import Tooltip from 'components/Tooltip';

interface ExpiresAtProps {
  fanRequest: FanRequest;
}

export default function ExpiresAt({ fanRequest }: ExpiresAtProps) {
  const { t } = useTranslation();

  const expiresAt = fanRequest.expiresAt;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className="flex ml-1 items-center relative group cursor-pointer text-12 bump-down-2 leading-tight"
        role="button"
        onClick={() => setIsOpen(true)}
      >
        {!!expiresAt ? (
          <Tooltip
            theme="socialieDark"
            content={t('fanRequestShow.topBar.expiresAt.tooltip')}
            arrow
          >
            <span className="text-light truncate hover:underline hover:text-dark">
              {expiresAt < new Date().getTime()
                ? t('fanRequestShow.topBar.expired', {
                    date: formatDate(expiresAt),
                  })
                : t('fanRequestShow.topBar.expires', {
                    date: formatDate(expiresAt),
                  })}
            </span>
          </Tooltip>
        ) : (
          <span className="flex-shrink-0 ml-0.5 first:ml-0 text-grey4 hover:text-dark underline whitespace-nowrap">
            {t('fanRequestShow.topBar.expiresAt.addButton')}
          </span>
        )}
      </div>
      <EditExpirationModal
        isOpen={isOpen}
        fanRequest={fanRequest}
        onRequestClose={() => setIsOpen(false)}
      />
    </>
  );
}
