import { useFetchFanSubmissionsQuery } from 'api/fanSubmissions';
import Loader from 'components/Loader';
import useFilterManager from 'hooks/useFilterManager';
import useSelectable, { SelectableProvider } from 'hooks/useSelectable';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFanSubmissions } from 'redux/reducers/fanSubmissions';
import { useAppSelector } from 'redux/store';
import FanRequest from 'types/FanRequest';
import FanSubmissionFilter from 'types/FanSubmissionFilter';
import {
  FanSubmissionList,
  FanSubmissionRatingFilter,
  FanSubmissionsNoResults,
  FanSubmissionBulkActions,
  FanSubmissionSort,
  FanSubmissionShow,
} from './components';
import { Route } from 'react-router';
import routes from 'helpers/routes';

interface Props {
  fanRequest: FanRequest;
}

const defaultFilter: FanSubmissionFilter = {
  rating: null,
  sort: 'newest',
};

export default function FanRequestSubmissions({ fanRequest }: Props) {
  const { t } = useTranslation();
  const { filter, updateFilter, debouncedFilter } = useFilterManager(
    defaultFilter,
    true
  );
  const [cursor, setCursor] = useState('');

  const handleUpdateFilter: typeof updateFilter = (changes, options) => {
    setCursor('');
    updateFilter(changes, options);
  };

  const fanSubmissions = useAppSelector(getFanSubmissions);
  const metadata = useAppSelector((state) => state.fanSubmissions.metadata);
  const { isFetching, error } = useFetchFanSubmissionsQuery(
    { requestId: fanRequest.id, cursor, filter: debouncedFilter },
    { refetchOnMountOrArgChange: true }
  );

  const loadNextPage = useCallback(() => {
    if (!!metadata?.cursor && metadata?.cursor !== cursor) {
      setCursor(metadata.cursor);
    }
  }, [metadata.cursor, cursor]);

  const hasNextPage =
    !isFetching &&
    !!fanSubmissions.length &&
    fanSubmissions.length < metadata.totalCount;

  // If the user deletes an entire page, load up the next one
  useEffect(() => {
    if (!fanSubmissions.length && !!metadata.totalCount && hasNextPage)
      loadNextPage();
  }, [fanSubmissions.length, metadata.totalCount, hasNextPage, loadNextPage]);

  const selectable = useSelectable(fanSubmissions.map((f) => f.id));

  return (
    <>
      <SelectableProvider value={selectable}>
        <div className="relative px-3 pt-18 pb-2 with-topbar">
          <div className="mb-4.5">
            <FanSubmissionRatingFilter
              value={filter.rating}
              onChange={(rating) => handleUpdateFilter({ rating })}
            />
          </div>

          {error ? (
            <div className="notice notice--error self-start">
              {t('fanRequestShow.submissions.error')}
            </div>
          ) : isFetching && cursor === '' ? (
            <Loader />
          ) : !metadata.totalCount ? (
            <FanSubmissionsNoResults
              onResetFilters={() =>
                handleUpdateFilter(defaultFilter, { debounce: false })
              }
            />
          ) : (
            <>
              <div className="mb-2 flex">
                {metadata && (
                  <FanSubmissionBulkActions
                    fanRequest={fanRequest}
                    totalCount={metadata.totalCount}
                    loadedCount={fanSubmissions.length}
                  />
                )}

                <div className="ml-auto">
                  <FanSubmissionSort
                    value={filter.sort}
                    onChange={(value) => handleUpdateFilter({ sort: value })}
                  />
                </div>
              </div>

              <FanSubmissionList
                fanSubmissions={fanSubmissions}
                fanRequest={fanRequest}
                isFetching={isFetching}
                hasNextPage={hasNextPage}
                loadNextPage={loadNextPage}
              />
            </>
          )}
        </div>
      </SelectableProvider>

      <Route path={routes.requests.fanSubmissionShow}>
        <FanSubmissionShow fanRequest={fanRequest} filter={filter} />
      </Route>
    </>
  );
}
