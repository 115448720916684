import TextInput from './TextInput';
import DatePicker from 'react-datepicker';
import { parse } from 'date-fns';
import TimeInputs from './TimeInputs';
import { IconCalendar } from 'icons';
import { useTranslation } from 'react-i18next';

interface Props {
  value: number;
  onChange: (value: number) => void;
}

export default function DateTimePicker(props: Props) {
  const { value, onChange } = props;
  const { t } = useTranslation();
  const date = new Date(value);
  const handleChangeDate = (newDate: Date) => {
    const nextValue = newDate;
    nextValue.setHours(date.getHours());
    nextValue.setMinutes(date.getMinutes());
    onChange(nextValue.getTime());
  };

  const handleChangeTime = (newTime: string) => {
    const nextValue = parse(newTime, 'h:mmaa', date);
    onChange(nextValue.getTime());
  };

  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <div className="max-w-40">
      <div className="flex items-center">
        <div className="flex-1 mr-1">
          <DatePicker
            selected={date}
            onChange={handleChangeDate}
            customInput={
              <TextInput
                rightIcon={IconCalendar}
                rightIconClassNames="hover:text-dark active:text-dark"
              />
            }
            minDate={new Date()}
          />
        </div>

        <div className="flex-1">
          <TimeInputs value={value} onChange={handleChangeTime} />
        </div>
      </div>

      {!!currentTimeZone && (
        <div className="text-12 text-light">
          {t('form.dateTimePicker.currentTimeZone', { currentTimeZone })}
        </div>
      )}
    </div>
  );
}
