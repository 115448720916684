import useKey from '@rooks/use-key';
import { useUpdateFanSubmissionMutation } from 'api/fanSubmissions';
import cx from 'classnames';
import {
  IconThumbsDownEmpty,
  IconThumbsDownFilled,
  IconThumbsUpEmpty,
  IconThumbsUpFilled,
} from 'icons';
import { createElement } from 'react';
import FanSubmission from 'types/FanSubmission';

interface Props {
  fanSubmission: FanSubmission;
  theme?: 'thumbnail' | 'sidebar';
  keyboard?: boolean;
}

export default function FanSubmissionRating(props: Props) {
  const { fanSubmission, keyboard, theme = 'sidebar' } = props;
  const [updateFanSubmission] = useUpdateFanSubmissionMutation();
  const buttonClassNames = (isActive: boolean) =>
    cx('block', {
      'w-4.5 h-4.5 rounded-full hover:bg-grey8 hover:text-dark p-1':
        theme === 'sidebar',
      'text-grey5': theme === 'sidebar' && !isActive,
      'text-dark': theme === 'sidebar' && isActive,
      'text-white w-3 h-3': theme === 'thumbnail',
      'opacity-[0.66725] group-hover:opacity-100':
        theme === 'thumbnail' && !isActive,
    });
  const iconClassNames = 'block w-full h-full';

  useKey(['ArrowUp'], () => {
    if (keyboard) rateSubmission('starred');
  });

  useKey(['ArrowDown'], () => {
    if (keyboard) rateSubmission('rejected');
  });

  const rateSubmission = (rating: FanSubmission['rating']) => {
    const nextRating = fanSubmission.rating === rating ? 'unrated' : rating;
    updateFanSubmission({ fanSubmission, changes: { rating: nextRating } });
  };

  return (
    <div
      className={cx('flex items-center', {
        'space-x-1.5': theme === 'thumbnail',
      })}
    >
      <button
        className={buttonClassNames(fanSubmission.rating === 'rejected')}
        onClick={() => rateSubmission('rejected')}
      >
        {createElement(
          fanSubmission.rating === 'rejected'
            ? IconThumbsDownFilled
            : IconThumbsDownEmpty,
          { className: iconClassNames }
        )}
      </button>

      <button
        className={buttonClassNames(fanSubmission.rating === 'starred')}
        onClick={() => rateSubmission('starred')}
      >
        {createElement(
          fanSubmission.rating === 'starred'
            ? IconThumbsUpFilled
            : IconThumbsUpEmpty,
          { className: iconClassNames }
        )}
      </button>
    </div>
  );
}
