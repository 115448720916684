import React, { useState, useRef, useEffect } from 'react';

const isServer = typeof window === 'undefined';

type Props = {
  defaultHeight?: number;
  visibleOffset?: number;
  root?: HTMLElement;
  renderPlaceholder?: (height: number) => React.ReactNode;
  wrapperProps?: React.ComponentProps<'div'>;
};

const RenderIfVisible: React.FC<Props> = ({
  defaultHeight = 300,
  visibleOffset = 1000,
  root = null,
  renderPlaceholder,
  wrapperProps,
  children,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(isServer);
  const placeholderHeight = useRef<number>(defaultHeight);
  const intersectionRef = useRef<HTMLDivElement>(null);

  // Set visibility with intersection observer
  useEffect(() => {
    const intersection = intersectionRef.current;
    if (intersection) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (
            typeof window !== undefined &&
            (window as any).requestIdleCallback
          ) {
            (window as any).requestIdleCallback(
              () => setIsVisible(entries[0].isIntersecting),
              {
                timeout: 600,
              }
            );
          } else {
            setIsVisible(entries[0].isIntersecting);
          }
        },
        { root, rootMargin: `${visibleOffset}px 0px ${visibleOffset}px 0px` }
      );
      observer.observe(intersection);
      return () => {
        if (intersection) {
          observer.unobserve(intersection);
        }
      };
    }
  }, [intersectionRef, root, visibleOffset]);

  // Set height after render
  useEffect(() => {
    if (intersectionRef.current && isVisible) {
      placeholderHeight.current = intersectionRef.current.offsetHeight;
    }
  }, [isVisible, intersectionRef]);

  return (
    <div ref={intersectionRef} {...wrapperProps}>
      {isVisible ? (
        <>{children}</>
      ) : renderPlaceholder ? (
        <>{renderPlaceholder(placeholderHeight.current)}</>
      ) : (
        <div style={{ height: placeholderHeight.current }} />
      )}
    </div>
  );
};

export default RenderIfVisible;
