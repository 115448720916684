import cx from 'classnames';
import formatDate from 'helpers/formatDate';
import useOverLimit from 'hooks/useOverLimit';
import usePlan from 'hooks/usePlan';
import { IconEdit, IconLive, IconPause } from 'icons';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import FanRequest from 'types/FanRequest';
import Tooltip from './Tooltip';

interface Props {
  fanRequest: FanRequest;
  theme?: 'inverted' | 'normal';
  size?: 'small' | 'normal';
}

export default function StatusPill(props: Props) {
  const { fanRequest, theme = 'normal', size = 'normal' } = props;
  const { t } = useTranslation();
  let status: 'published' | 'draft' | 'paused' = fanRequest.status;
  const overLimit = useOverLimit();
  const plan = usePlan();
  if (overLimit && status === 'published') status = 'paused';

  const icon = {
    published: IconLive,
    draft: IconEdit,
    paused: IconPause,
  }[status];

  return (
    <Tooltip
      disabled={status !== 'paused'}
      theme="socialieDark"
      interactive
      content={
        <Trans
          i18nKey={`statusPill.overLimit.${overLimit}`}
          values={{
            resetDate: formatDate(plan.currentTermEnd || plan.trialEnd || 0),
          }}
          components={{
            contactLink: (
              /* eslint-disable-next-line jsx-a11y/anchor-has-content */
              <a href="mailto:support@socialie.com" className="underline" />
            ),
          }}
        />
      }
    >
      <div
        className={cx(
          'inline-flex items-center border-solid rounded-full px-1  font-bold whitespace-nowrap',
          {
            'border-2 text-14 h-3': size === 'normal',
            'border text-12 h-2.5': size === 'small',
            'bg-grey4 border-grey4 text-white': status === 'draft',
            'bg-transparent border-error uppercase text-error':
              status === 'published' && theme === 'normal',
            'bg-transparent border-white uppercase text-white':
              status === 'published' && theme === 'inverted',
            'bg-grey1 border-grey1 text-white': status === 'paused',
          }
        )}
      >
        {icon &&
          React.createElement(icon, {
            className: cx('block mr-0.5', {
              'w-2 h-2': size === 'normal',
              'w-1.75 h-1.75': size === 'small',
            }),
          })}
        <span className="bump-up-1.5">{t(`statusPill.${status}`)}</span>
      </div>
    </Tooltip>
  );
}
