import ShareUrl from 'components/ShareUrl';
import { IconCoachmarkArrow1, IconDownload } from 'icons';
import { useTranslation } from 'react-i18next';
import FanRequest from 'types/FanRequest';

interface Props {
  fanRequest: FanRequest;
}

export default function FanRequestEmpty({ fanRequest }: Props) {
  const { t } = useTranslation();
  return (
    <div className="w-full flex-1 flex-v-center">
      <div className="mx-auto relative inline-flex items-center">
        <a
          href={`${fanRequest.qrCodeUrl}?download=1&size=400`}
          className="group relative mr-2 w-19 h-19 border-default rounded-lg overflow-hidden"
        >
          <img
            src={fanRequest.qrCodeUrl}
            alt="QR Code"
            className="mr-2 w-full h-full"
          />

          <span className="block absolute-center z-10 w-5.5 h-5.5 rounded-full bg-white p-0.75 opacity-0 group-hover:opacity-100">
            <span className="block w-full h-full rounded-full bg-socialiePink text-white p-1">
              <IconDownload className="block w-full h-full" />
            </span>
          </span>
        </a>

        <div>
          <div className="text-20 font-black text-dark mb-1">
            {t('fanRequestShow.empty.share')}
          </div>
          <ShareUrl url={fanRequest.url} />
        </div>

        <IconCoachmarkArrow1
          className="absolute w-8 h-8 left-15 -top-3 text-dark pointer-events-none"
          style={{
            transform: 'scaleY(-1) rotate(-45deg)',
          }}
        />
      </div>
    </div>
  );
}
