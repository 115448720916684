import { SecondaryTopBar } from 'components/Layout/components';
import Usage from 'components/Usage';
import { useTranslation } from 'react-i18next';

function SettingsUsersTopBar() {
  const { t } = useTranslation();

  return (
    <SecondaryTopBar
      heading={t('settings.users.main')}
      subHeading={t('settings.users.topBar.desc')}
    >
      <div className="w-24 text-dark">
        <Usage.UsersBar />
      </div>
    </SecondaryTopBar>
  );
}

export default SettingsUsersTopBar;
