import { useContext, useEffect } from 'react';
import { SocketContext } from './SocketProvider';

export function useSocket() {
  const context = useContext(SocketContext);
  return context;
}

export function useSocketEvent(name: string, callback: Function) {
  const { socket } = useSocket();

  useEffect(() => {
    if (!socket) return;
    socket.on(name, callback);
    return () => {
      socket.off(name, callback);
    };
  }, [name, callback, socket]);
}
