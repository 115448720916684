import { useCreateDomainMutation } from 'api/domains';
import Form from 'components/Form';
import Modal from 'components/Modal';
import routeFor from 'helpers/routeFor';
import routes from 'helpers/routes';
import useFlashMessages from 'hooks/useFlashMessages';
import useTranslateErrors from 'hooks/useTranslateErrors';
import { useRef } from 'react';
import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface Props {
  onRequestClose: () => void;
}

export default function SettingsDomainsAddModal(props: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { onRequestClose } = props;
  const [value, setValue] = useState('');
  const [createDomain, { isLoading }] = useCreateDomainMutation();
  const [errors, setErrors] = useState<string[] | null>(null);
  const { t } = useTranslation();
  const { addFlashMessage } = useFlashMessages();
  const translateErrors = useTranslateErrors();
  const history = useHistory();

  const handleSave = async () => {
    const result = await createDomain(value);
    if ('error' in result) {
      if ('data' in result.error && result.error.status === 422) {
        setErrors(result.error.data.errors.domain);
      } else {
        addFlashMessage(t('global.unexpectedError'), {
          isError: true,
        });
      }
    } else {
      addFlashMessage(t('settings.domains.addModal.success'));
      history.push(routeFor(routes.settings.domains.dns, result.data.id));
    }
  };

  return (
    <Modal
      onRequestClose={onRequestClose}
      renderHeading={t('settings.domains.addModal.heading')}
      renderActions={
        <>
          <button
            className="btn btn--grey btn--inlineBlock"
            onClick={onRequestClose}
            type="button"
          >
            {t('global.cancel')}
          </button>

          <button
            className="btn btn--socialie btn--inlineBlock"
            disabled={isLoading || !value}
            onClick={handleSave}
            data-loading={isLoading}
          >
            {t('global.save')}
          </button>
        </>
      }
    >
      <div className="mb-2 text-14">
        <Trans
          i18nKey="settings.domains.addModal.helperText"
          components={{ b: <strong /> }}
        />
      </div>
      <Form onSubmit={handleSave}>
        <Form.TextInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          prefix="https://"
          errors={translateErrors(errors)}
          ref={inputRef}
          autoFocus
        />
      </Form>
    </Modal>
  );
}
