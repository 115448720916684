import { useBulkUpdateFanSubmissionsMutation } from 'api/fanSubmissions';
import FanSubmissionDeleteModal from 'components/FanSubmissionDeleteModal';
import RecordCount from 'components/RecordCount';
import { useSelectableContext } from 'hooks/useSelectable';
import { IconThumbsDownEmpty, IconThumbsUpEmpty, IconTrash } from 'icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFanSubmissionsById } from 'redux/reducers/fanSubmissions';
import { useAppSelector } from 'redux/store';
import FanRequest from 'types/FanRequest';
import FanSubmission from 'types/FanSubmission';
import {
  FanSubmissionBulkAction,
  FanSubmissionBulkDownload,
} from './components';

interface Props {
  totalCount: number;
  loadedCount: number;
  fanRequest: FanRequest;
}

export default function FanSubmissionBulkActions(props: Props) {
  const { totalCount, loadedCount, fanRequest } = props;
  const { t } = useTranslation();
  const { selectAll, selectNone, selectedIds } = useSelectableContext();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const selectedFanSubmissions = useAppSelector((state) =>
    getFanSubmissionsById(state, selectedIds as number[])
  );
  const [bulkUpdateFanSubmissions] = useBulkUpdateFanSubmissionsMutation();

  const rateSubmissions = (rating: FanSubmission['rating']) => {
    bulkUpdateFanSubmissions({
      fanSubmissions: selectedFanSubmissions,
      changes: { rating },
    });
  };

  return (
    <>
      <div className="flex items-center mb-2 h-5">
        <div className="mr-1">
          <RecordCount
            msgPrefix="fanRequestShow.submissions.count"
            totalCount={totalCount}
            loadedCount={loadedCount}
            selectAll={selectAll}
            selectNone={selectNone}
            selectedCount={selectedIds.length}
          />
        </div>

        {!!selectedIds.length && (
          <>
            <FanSubmissionBulkAction
              title={t('fanRequestShow.submissions.bulkActions.star')}
              icon={IconThumbsUpEmpty}
              onClick={() => rateSubmissions('starred')}
            />

            <FanSubmissionBulkAction
              title={t('fanRequestShow.submissions.bulkActions.reject')}
              icon={IconThumbsDownEmpty}
              onClick={() => rateSubmissions('rejected')}
            />

            <FanSubmissionBulkDownload
              fanSubmissions={selectedFanSubmissions}
            />

            <FanSubmissionBulkAction
              title={t('fanRequestShow.submissions.bulkActions.delete')}
              icon={IconTrash}
              onClick={() => setIsDeleteModalOpen(true)}
            />
          </>
        )}
      </div>

      <FanSubmissionDeleteModal
        fanRequest={fanRequest}
        fanSubmissions={selectedFanSubmissions}
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
      />
    </>
  );
}
