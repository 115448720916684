import { useBulkDeleteFanSubmissionsMutation } from 'api/fanSubmissions';
import useFlashMessages from 'hooks/useFlashMessages';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FanRequest from 'types/FanRequest';
import FanSubmission from 'types/FanSubmission';
import Modal from './Modal';

interface Props {
  fanRequest: FanRequest;
  fanSubmissions: FanSubmission[];
  isOpen: boolean;
  onRequestClose: () => void;
  onAfterDelete?: () => void;
}

export default function FanSubmissionDeleteModal(props: Props) {
  const isMounted = useRef(true);
  const { addFlashMessage } = useFlashMessages();
  const [bulkDeleteFanSubmissions, { isLoading }] =
    useBulkDeleteFanSubmissionsMutation();
  const { isOpen, onRequestClose, fanSubmissions, fanRequest, onAfterDelete } =
    props;
  const { t } = useTranslation();
  const count = fanSubmissions.length;

  const handleDelete = async () => {
    const result = await bulkDeleteFanSubmissions({
      fanSubmissions,
      fanRequestId: fanRequest.id,
    });

    if ('data' in result) {
      addFlashMessage(t('fanSubmissionDeleteModal.success', { count }));
      onAfterDelete?.();
      if (isMounted.current) onRequestClose();
    } else {
      addFlashMessage(t('global.unexpectedError'), { isError: true });
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      renderHeading={t('fanSubmissionDeleteModal.heading', { count })}
      renderActions={
        <>
          <button
            className="btn btn--grey btn--inlineBlock"
            onClick={onRequestClose}
          >
            {t('global.cancel')}
          </button>

          <button
            className="btn btn--socialie btn--inlineBlock"
            disabled={isLoading}
            data-loading={isLoading}
            onClick={handleDelete}
          >
            {t('fanSubmissionDeleteModal.submit', { count })}
          </button>
        </>
      }
    >
      {t('fanSubmissionDeleteModal.description', { count })}
    </Modal>
  );
}
