import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  isSaving: boolean;
  onSave: () => void;
}

export default function SaveAndPreviewModal(props: Props) {
  const { isOpen, onRequestClose, isSaving, onSave } = props;
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      renderHeading={t('fanRequestBuilder.actions.savePreviewModal.heading')}
      renderActions={
        <>
          <button className="btn btn--grey" onClick={onRequestClose}>
            {t('global.cancel')}
          </button>

          <button
            className="btn btn--socialie"
            disabled={isSaving}
            data-loading={isSaving}
            onClick={onSave}
          >
            {t('global.save')}
          </button>
        </>
      }
    >
      {t('fanRequestBuilder.actions.savePreviewModal.body')}
    </Modal>
  );
}
