import React from 'react';
import Label from './Label';
import Description from './Description';
import Errors from './Errors';
interface Props {
  label?: React.ReactNode;
  required?: boolean;
  name?: string;
  errors?: string | string[] | null;
  description?: string;
  children: React.ReactNode;
}

export default function Field(props: Props) {
  const { label, required, name, errors, description, children } = props;

  return (
    <div className="mb-3 last:mb-0">
      {(!!label || !!description) && (
        <div className="mb-1">
          {!!label && (
            <Label name={name}>
              {label}
              {required && '*'}
            </Label>
          )}

          {!!description && <Description>{description}</Description>}
        </div>
      )}

      {children}

      {!!errors?.length && <Errors errors={errors} />}
    </div>
  );
}
