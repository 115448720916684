import formatDate from 'helpers/formatDate';
import { add } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Subscription from 'types/Subscription';
import SettingsSubscriptionSection from '../SettingsSubscriptionSection';
import {
  SettingsSubscriptionPlanCancel,
  SettingsSubscriptionPlanReactivate,
} from './components';

interface Props {
  subscription: Subscription;
}

export default function SettingsSubscriptionPlan(props: Props) {
  const { subscription } = props;
  const { status } = subscription;
  const { t } = useTranslation();
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <SettingsSubscriptionSection
      heading={
        <span className="flex items-center">
          <span className="mr-1">
            {t('settings.subscription.plan.yourPlan')}
          </span>
          {status === 'in_trial' && (
            <span className="bump-down-2 flex items-center rounded-full text-brightBlue bg-blueBg px-1.5 h-3">
              <span className="bump-up-1 text-14 font-bold tracking-normal">
                {t('settings.subscription.plan.trial')}
              </span>
            </span>
          )}
        </span>
      }
    >
      <h3 className="h3">{subscription.plan.name}</h3>
      <div className="text-dark text-14 mb-1.5">
        <span className="font-bold">
          {formatter.format(subscription.planAmount / 100)}{' '}
          {t(
            `settings.subscription.plan.frequency.${subscription.billingPeriodUnit}`
          )}
          .{' '}
        </span>
        {['active', 'in_trial'].includes(status) &&
        subscription.nextBillingAt ? (
          t('settings.subscription.plan.renewal', {
            renewalDate: formatDate(subscription.nextBillingAt, {
              time: false,
              month: 'long',
              forceYear: true,
            }),
          })
        ) : ['non_renewing', 'in_trial'].includes(status) ? (
          <span className="text-error">
            {t('settings.subscription.plan.cancelDate', {
              cancelDate: formatDate(
                add(
                  subscription.currentTermEnd || subscription.trialEnd || -1,
                  { days: 1 }
                ),
                {
                  time: false,
                  month: 'long',
                  forceYear: true,
                }
              ),
            })}
          </span>
        ) : status === 'cancelled' ? (
          t('settings.subscription.plan.cancelled')
        ) : status === 'paused' ? (
          t('settings.subscription.plan.paused')
        ) : null}
      </div>

      {status === 'active' ||
      (status === 'in_trial' && subscription.nextBillingAt) ? (
        <SettingsSubscriptionPlanCancel subscription={subscription} />
      ) : ['non_renewing', 'paused', 'cancelled', 'in_trial'].includes(
          status
        ) ? (
        <SettingsSubscriptionPlanReactivate subscription={subscription} />
      ) : null}
    </SettingsSubscriptionSection>
  );
}
