import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import compact from 'lodash/compact';
import fanSubmissionApi from 'api/fanSubmissions';
import FanSubmission from 'types/FanSubmission';
import { RootState } from 'redux/store';

const fanSubmissionsAdapter = createEntityAdapter<FanSubmission>();

const { reducer } = createSlice({
  name: 'fanSubmissions',
  initialState: fanSubmissionsAdapter.getInitialState({
    metadata: { totalCount: 0, cursor: '' },
  }),
  reducers: {},
  extraReducers: (builder) => {
    // Optimistic update
    builder.addMatcher(
      fanSubmissionApi.endpoints.updateFanSubmission.matchPending,
      (state, action) => {
        const { fanSubmission, changes } = action.meta.arg.originalArgs;
        fanSubmissionsAdapter.updateOne(state, {
          id: fanSubmission.id,
          changes,
        });
      }
    );

    builder.addMatcher(
      fanSubmissionApi.endpoints.bulkUpdateFanSubmissions.matchPending,
      (state, action) => {
        const { fanSubmissions, changes } = action.meta.arg.originalArgs;

        fanSubmissionsAdapter.updateMany(
          state,
          fanSubmissions.map(({ id }) => ({ id, changes }))
        );
      }
    );

    // Rollback optimistic update if update fails
    builder.addMatcher(
      fanSubmissionApi.endpoints.updateFanSubmission.matchRejected,
      (state, action) => {
        const { fanSubmission } = action.meta.arg.originalArgs;
        fanSubmissionsAdapter.upsertOne(state, fanSubmission);
      }
    );

    builder.addMatcher(
      fanSubmissionApi.endpoints.bulkUpdateFanSubmissions.matchRejected,
      (state, action) => {
        const { fanSubmissions } = action.meta.arg.originalArgs;
        fanSubmissionsAdapter.upsertMany(state, fanSubmissions);
      }
    );

    // Fetch pending (reset the list)
    builder.addMatcher(
      fanSubmissionApi.endpoints.fetchFanSubmissions.matchPending,
      (state, action) => {
        if (action.meta.arg.originalArgs.cursor === '') {
          fanSubmissionsAdapter.setAll(state, []);
        }
      }
    );

    // Fetch success
    builder.addMatcher(
      fanSubmissionApi.endpoints.fetchFanSubmissions.matchFulfilled,
      (state, action) => {
        const data = action.payload;
        state.metadata = data.metadata;

        // We do this manually because when we fetch individual submissions
        // we add them to entities but not ids and that trips up `upsertMany`
        data.fanSubmissions.forEach((fs) => {
          state.entities[fs.id] = { ...state.entities[fs.id], ...fs };
          if (!state.ids.includes(fs.id)) state.ids.push(fs.id);
        });
      }
    );

    builder.addMatcher(
      fanSubmissionApi.endpoints.fetchFanSubmission.matchFulfilled,
      (state, action) => {
        state.entities[action.payload.id] = action.payload;
      }
    );

    // Delete success
    builder.addMatcher(
      fanSubmissionApi.endpoints.bulkDeleteFanSubmissions.matchFulfilled,
      (state, action) => {
        const { fanSubmissions } = action.meta.arg.originalArgs;
        state.metadata.totalCount =
          state.metadata.totalCount - fanSubmissions.length;
        fanSubmissionsAdapter.updateMany(
          state,
          fanSubmissions.map(({ id }) => ({ id, changes: { deleted: true } }))
        );
      }
    );
  },
});

export default reducer;

// Selectors
const selectors = fanSubmissionsAdapter.getSelectors();

export const getFanSubmissions = (state: RootState) =>
  selectors.selectAll(state.fanSubmissions).filter((f) => !f.deleted);

export const getFanSubmissionsById = (state: RootState, ids: number[]) => {
  return compact(ids.map((id) => state.fanSubmissions.entities[id])).filter(
    (f) => !f.deleted
  );
};

export const getFanSubmission = (state: RootState, id: number) => {
  return selectors.selectById(state.fanSubmissions, id);
};
