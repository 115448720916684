import { IconArrowLeft, IconArrowRight } from 'icons';

interface Props {
  onOpenNext?: () => void;
  onOpenPrev?: () => void;
}

export default function FanSubmissionSidebarNavigation(props: Props) {
  const { onOpenNext, onOpenPrev } = props;

  return (
    <div className="inline-flex items-stretch relative rounded-xl bg-greyBg shadow-inner px-1 space-x-1.5 py-0.5">
      <button
        onClick={onOpenNext}
        disabled={!onOpenNext}
        className="w-3 h-3 text-dark p-0.25 disabled:opacity-50"
      >
        <IconArrowLeft className="block w-full h-full" />
      </button>

      <button
        onClick={onOpenPrev}
        disabled={!onOpenPrev}
        className="w-3 h-3 text-dark p-0.25 disabled:opacity-50"
      >
        <IconArrowRight className="block w-full h-full" />
      </button>
    </div>
  );
}
