import React from 'react';
import cx from 'classnames';
import { IconNotVisible, IconRemove, IconVisible } from 'icons';
import RemainingChars from './RemainingChars';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { togglePasswordsVisible } from 'redux/reducers/ui';

interface Props {
  value?: React.ComponentProps<'input'>['value'];
  errors?: string | string[] | null;
  prefix?: string;
  disabled?: boolean;
  isFocused?: boolean;
  children?: React.ReactNode;
  rightIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  leftIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  rightIconClassNames?: string;
  leftIconClassNames?: string;
  onClickIcon: () => void;
  onClear?: () => void;
  multiline?: boolean;
  maxLength?: number;
  isPassword?: boolean;
  variant?: 'center';
}

export default function InputContainer(props: Props) {
  const {
    value,
    errors,
    isFocused,
    prefix,
    disabled,
    multiline,
    children,
    rightIcon,
    leftIcon,
    rightIconClassNames,
    leftIconClassNames,
    onClickIcon,
    maxLength,
    onClear,
    isPassword,
    variant,
  } = props;

  const passwordsVisible = useAppSelector((state) => state.ui.passwordsVisible);
  const dispatch = useAppDispatch();

  const handleFocus: React.DOMAttributes<HTMLDivElement>['onClick'] = (e) => {
    const el = e.currentTarget;
    const input = el.querySelector('input,textarea') as HTMLInputElement;
    input?.focus();
  };

  return (
    <div
      onClick={handleFocus}
      className={cx('flex w-full py-1 border border-solid rounded-lg', {
        'pl-1.5 pr-1': variant !== 'center',
        'px-1': variant === 'center',
        'h-5 items-center': !multiline,
        'items-start': multiline,
        'ring ring-opacity-20': isFocused,
        'border-brightBlue ring-brightBlue':
          isFocused && (!errors || !errors.length),
        'bg-white': !disabled && (!errors || !errors.length),
        'border-grey6': !isFocused && !disabled && (!errors || !errors.length),
        'border-grey6 bg-grey8': disabled && (!errors || !errors.length),
        'border-error bg-errorBg': !!errors?.length,
        // 'ring-error': isFocused && !!errors?.length,
      })}
    >
      {!!leftIcon &&
        React.createElement(leftIcon, {
          className: cx(
            'block w-3 h-3 text-light mr-1',
            {
              'mt-0.5': multiline,
            },
            leftIconClassNames
          ),
          role: 'button',
          onClick: onClickIcon,
        })}
      {!!prefix && (
        <span className="text-light bump-down-1 whitespace-nowrap">
          {prefix}
        </span>
      )}

      {children}

      {isPassword ? (
        <button
          className="w-3 h-3 text-grey6 ml-1 hover:text-light"
          type="button"
          tabIndex={-1}
          onClick={() => dispatch(togglePasswordsVisible())}
        >
          {React.createElement(
            passwordsVisible ? IconVisible : IconNotVisible,
            {
              className: 'block w-full h-full',
            }
          )}
        </button>
      ) : !!onClear ? (
        <button
          className={cx('w-2 h-2 ml-1 text-lightIcon', { 'mt-0.5': multiline })}
          onClick={onClear}
          type="button"
        >
          <IconRemove className="block w-full h-full" />
        </button>
      ) : maxLength ? (
        <RemainingChars maxLength={maxLength} value={value} />
      ) : !!rightIcon ? (
        React.createElement(rightIcon, {
          className: cx(
            'block w-3 h-3 text-light ml-1',
            {
              'mt-0.5': multiline,
            },
            rightIconClassNames
          ),
          role: 'button',
          type: 'button',
          onClick: onClickIcon,
        })
      ) : null}
    </div>
  );
}
