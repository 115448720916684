import { useCheckDomainSslMutation } from 'api/domains';
import Btn from 'components/Btn';
import Modal from 'components/Modal';
import useFlashMessages from 'hooks/useFlashMessages';
import { useTranslation } from 'react-i18next';
import Domain from 'types/Domain';

interface Props {
  domain: Domain;
  onRequestClose: () => void;
}

export default function SettingsDomainsSSLModal(props: Props) {
  const { domain, onRequestClose } = props;
  const { t } = useTranslation();
  const { addFlashMessage } = useFlashMessages();
  const [checkSsl, { isLoading }] = useCheckDomainSslMutation();

  const handleCheckSsl = async () => {
    const result = await checkSsl(domain.id);
    if ('error' in result) {
      addFlashMessage(t('global.unexpectedError'), { isError: true });
    }
    onRequestClose();
  };

  return (
    <Modal
      onRequestClose={onRequestClose}
      renderHeading={t('settings.domains.ssl.modalHeading')}
      theme="large"
    >
      {domain.validationCname && domain.validationValue ? (
        <>
          <ol className="mb-3 instruction-list">
            <li>{t(`settings.domains.instructions.0`)}</li>
            <li>{t(`settings.domains.instructions.1`)}</li>
            <li>
              {t(`settings.domains.instructions.2`)} <strong>CNAME</strong>
            </li>
            <li>
              {t(`settings.domains.instructions.3`)}{' '}
              <strong>{domain.validationCname}</strong>
            </li>
            <li>
              {t(`settings.domains.instructions.4`)}{' '}
              <strong>{domain.validationValue}</strong>
            </li>
          </ol>

          {t(`settings.domains.ssl.modalDelayNote`)}
        </>
      ) : (
        t(`settings.domains.ssl.modalProblem`)
      )}

      <Modal.Actions>
        <Btn
          onClick={handleCheckSsl}
          isLoading={isLoading}
          variant="inlineBlock"
        >
          {t('settings.domains.ssl.modalRecheck')}
        </Btn>
      </Modal.Actions>
    </Modal>
  );
}
