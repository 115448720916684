import { useDeleteDomainMutation } from 'api/domains';
import Modal from 'components/Modal';
import Tooltip from 'components/Tooltip';
import useFlashMessages from 'hooks/useFlashMessages';
import { IconTrash } from 'icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Domain from 'types/Domain';

interface Props {
  domain: Domain;
}

export default function SettingsDomainsDelete(props: Props) {
  const { domain } = props;
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteDomain, { isLoading }] = useDeleteDomainMutation();
  const { addFlashMessage } = useFlashMessages();

  const handleDelete = async () => {
    const result = await deleteDomain(domain.id);
    if ('error' in result) {
      addFlashMessage(t('global.unexpectedError', { isError: true }));
    } else {
      addFlashMessage(t('settings.domains.delete.success'));
      setIsModalOpen(false);
    }
  };

  return (
    <>
      <Tooltip
        content={t('settings.domains.delete.tooltip')}
        theme="socialieDark"
        placement="top"
        arrow
      >
        <button
          className="w-4 h-4 p-0.5 text-lightIcon hover:text-dark"
          onClick={() => setIsModalOpen(true)}
        >
          <IconTrash className="block w-full h-full" />
        </button>
      </Tooltip>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        renderHeading={t('settings.domains.delete.modalHeading')}
        renderActions={
          <>
            <button
              className="btn btn--grey btn--inlineBlock"
              onClick={() => setIsModalOpen(false)}
            >
              {t('global.cancel')}
            </button>
            <button
              className="btn btn--socialie btn--inlineBlock"
              onClick={handleDelete}
              data-loading={isLoading}
              disabled={isLoading}
            >
              {t('settings.domains.delete.continue')}
            </button>
          </>
        }
      >
        {t('settings.domains.delete.modalContent', { domain: domain.domain })}
      </Modal>
    </>
  );
}
