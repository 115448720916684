import formatDate from 'helpers/formatDate';
import routeFor from 'helpers/routeFor';
import routes from 'helpers/routes';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Estimate from 'types/Estimate';

interface Props {
  estimate: Estimate;
}

export default function SignUpPaymentPlan({ estimate }: Props) {
  const { t } = useTranslation();
  const isMonthly = estimate.planId.match('monthly');
  const sectionClassNames = 'border-b-default pb-2.5 mb-2.5';
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <>
      <div className="bg-white p-2 mb-2.5 text-14 text-dark">
        <div className={sectionClassNames}>
          <span className="font-bold">{estimate.description}</span>
        </div>

        <div className={sectionClassNames}>
          <div className="mb-1.5">
            <div className="flex items-center justify-between">
              <div className="font-bold">
                {t(
                  `onboarding.signUp.payment.plan.${
                    isMonthly ? 'payMonthly' : 'payAnnually'
                  }`
                )}
              </div>
              <div>{formatter.format(estimate.total / 100)}</div>
            </div>

            <Link
              to={routeFor(
                routes.auth.signUp,
                isMonthly
                  ? estimate.planId.replace('monthly', 'annual')
                  : estimate.planId.replace('annual', 'monthly')
              )}
              className="underline"
            >
              {t(
                `onboarding.signUp.payment.plan.${
                  isMonthly ? 'switchToAnnual' : 'switchToMonthly'
                }`
              )}
            </Link>
          </div>
          <div>{t('onboarding.signUp.payment.plan.plusTax')}</div>
        </div>

        <div className={sectionClassNames}>
          <div className="flex items-center justify-between">
            <div>{t('onboarding.signUp.payment.plan.dueAfterTrial')}</div>
            <div>{formatter.format(estimate.total / 100)}</div>
          </div>
        </div>

        <div className="flex items-center justify-between font-bold">
          <span>{t('onboarding.signUp.payment.plan.dueNow')}</span>
          <span>$0</span>
        </div>
      </div>

      <div className="px-2 text-14 text-light">
        {t('onboarding.signUp.payment.plan.subscriptionStart', {
          date: formatDate(estimate.nextBillingAt, {
            forceYear: true,
            time: false,
          }),
        })}
      </div>
    </>
  );
}
