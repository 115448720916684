import React, { createContext, useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import * as io from 'socket.io-client';
import { v4 as uuid } from 'uuid';

interface Props {
  children: React.ReactNode;
}

const SOCKET_SERVER_URL =
  process.env['REACT_APP_SOCKET_SERVER_URL'] ||
  process.env['REACT_APP_API_SERVER_URL'] ||
  '';

export const SocketContext = createContext<{
  socketId: string | null;
  socket: SocketIOClient.Socket | null;
}>({
  socketId: null,
  socket: null,
});

export default function SocketProvider({ children }: Props) {
  const [socketId, setSocketId] = useState<string | null>(null);
  const socketRef = useRef<SocketIOClient.Socket | null>(null);

  useEffect(() => {
    const connectSocket = () => {
      socketRef.current = io.connect(SOCKET_SERVER_URL);
      const socketId = uuid();
      const socket = socketRef.current;

      socket.on('connect', () => {
        socket.emit('authenticate', { uuid: socketId });
        setSocketId(socketId);
      });

      socket.on('disconnect', () => {
        setSocketId(null);
        socket.open();
      });
    };

    connectSocket();
  }, []);

  return (
    <SocketContext.Provider value={{ socketId, socket: socketRef.current }}>
      {children}
    </SocketContext.Provider>
  );
}
