import Form from 'components/Form';
import { useTranslation } from 'react-i18next';
import { IconVideo, IconPhoto } from 'icons';

interface Props {
  value: string;
  errors?: string[];
  onChange: (value: string) => void;
}

export default function MediaType({ value, onChange, errors }: Props) {
  const { t } = useTranslation();
  return (
    <Form.Field
      required
      label={t('fanRequestBuilder.form.mediaType.label')}
      errors={errors}
    >
      <Form.ToggleGroup>
        <Form.ToggleGroup.Option
          isSelected={value === 'video'}
          onSelect={() => onChange('video')}
          title={t('fanRequestBuilder.form.mediaType.video')}
          icon={IconVideo}
        />

        <Form.ToggleGroup.Option
          isSelected={value === 'photo'}
          onSelect={() => onChange('photo')}
          title={t('fanRequestBuilder.form.mediaType.photo')}
          icon={IconPhoto}
        />

        <Form.ToggleGroup.Option
          isSelected={value === 'both'}
          onSelect={() => onChange('both')}
          title={t('fanRequestBuilder.form.mediaType.both')}
        />
      </Form.ToggleGroup>
    </Form.Field>
  );
}
