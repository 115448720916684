import { AnyAction } from 'redux';
import { RootState } from 'redux/store';

export default function errorsReducer(
  state: { [key: string]: any } = {},
  action: AnyAction
) {
  const { type } = action;
  const matches = /(.*)\/(pending|rejected)/.exec(type);

  if (!matches) return state;

  const [, requestName, requestState] = matches;

  let key = requestName;

  const id = getIdFromAction(action);
  if (id) key = `${key}:${id}`;

  return {
    ...state,
    [key]: requestState === 'rejected' ? action.payload || true : false,
  };
}

const getIdFromAction = (action: AnyAction) => {
  const arg = action.meta?.arg;
  if (!arg) return;
  if (arg.id) return arg?.id;
  if (typeof arg === 'number' || typeof arg === 'string') return arg;
};

// Selectors
export const getError = (state: RootState, key: string, id?: any) => {
  let lookup = key;
  if (id) lookup = `${lookup}:${id}`;
  return state.errors[lookup];
};
