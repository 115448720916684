import { useUpdateProfileMutation } from 'api/user';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import User from 'types/User';
import UserParams from 'types/UserParams';
import ValidationErrorPayload from 'types/ValidationErrorPayload';
import useFlashMessages from './useFlashMessages';

interface Callbacks {
  onSuccess?: () => void;
  onError?: () => void;
  onFailedValidation?: () => void;
}

export default function useUpdateProfile(
  callbacks?: Callbacks
): [
  (values: Partial<UserParams>) => Promise<void>,
  { isLoading: boolean; errors: null | ValidationErrorPayload<User> }
] {
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const { addFlashMessage } = useFlashMessages();
  const { t } = useTranslation();
  const [errors, setErrors] = useState<null | ValidationErrorPayload<User>>(
    null
  );

  const handleSave = async (values: Partial<UserParams>) => {
    setErrors(null);
    const result = await updateProfile(values);

    if ('error' in result) {
      if ('data' in result.error && result.error.status === 422) {
        setErrors(result.error.data.errors);
        callbacks?.onFailedValidation?.();
      } else {
        addFlashMessage(t('global.unexpectedError'), { isError: true });
        callbacks?.onError?.();
      }
    } else {
      callbacks?.onSuccess?.();
    }
  };

  return [handleSave, { isLoading, errors }];
}
