import { useUpdateFanAccountUserMutation } from 'api/fanAccountUser';
import Form from 'components/Form';
import useFlashMessages from 'hooks/useFlashMessages';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FanAccountUser from 'types/FanAccountUser';
import User from 'types/User';

interface Props {
  fanAccountUser: FanAccountUser;
  currentUser: User;
}

export default function SettingsUsersRole(props: Props) {
  const { fanAccountUser, currentUser } = props;
  const { addFlashMessage } = useFlashMessages();
  const [updateFanAccountUser] = useUpdateFanAccountUserMutation();

  const { t } = useTranslation();

  const [role, setRole] = useState(fanAccountUser.role);

  const options = [
    {
      value: 'member',
      label: t('settings.users.roles.member.label'),
    },
    {
      value: 'owner',
      label: t('settings.users.roles.owner.label'),
    },
  ];

  const selectedOption = options.find((o) => o.value === role);

  const handleChange = async (value: string) => {
    const prevRole = role;
    setRole(value);
    const result = await updateFanAccountUser({
      id: fanAccountUser.id,
      role: value,
    });

    if ('error' in result) {
      setRole(prevRole);
      addFlashMessage(t('global.unexpectedError'), { isError: true });
    } else {
      addFlashMessage(t('settings.users.roleUpdatedSuccess'));
    }
  };
  return (
    <Form.Select
      skipField
      options={options}
      isDisabled={fanAccountUser.user.id === currentUser.id}
      onChange={(o) => (o ? handleChange(o.value) : undefined)}
      value={selectedOption}
      size="small"
    />
  );
}
