import {
  useReactivateSubscriptionMutation,
  useResumeSubscriptionTrialMutation,
} from 'api/subscriptions';
import Modal from 'components/Modal';
import { add } from 'date-fns';
import formatDate from 'helpers/formatDate';
import useFlashMessages from 'hooks/useFlashMessages';
import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Subscription from 'types/Subscription';

interface Props {
  subscription: Subscription;
}

export default function SettingsSubscriptionPlanReactivate(props: Props) {
  const { subscription } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reactivateSubscription, { isLoading: isReactivateLoading }] =
    useReactivateSubscriptionMutation();
  const [resumeTrial, { isLoading: isResumeLoading }] =
    useResumeSubscriptionTrialMutation();
  const isLoading = isReactivateLoading || isResumeLoading;

  const { addFlashMessage } = useFlashMessages();
  const { t } = useTranslation();

  const handleReactivate = async () => {
    const result =
      subscription.status === 'in_trial'
        ? await resumeTrial()
        : await reactivateSubscription();
    if ('error' in result) {
      addFlashMessage(t('global.unexpectedError'), { isError: true });
    }
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <>
      <button
        className="btn btn--socialie btn--inlineBlock btn--small"
        onClick={() => setIsModalOpen(true)}
      >
        {t('settings.subscription.plan.reactivate')}
      </button>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        renderHeading={t('settings.subscription.plan.reactivateModal.heading')}
        renderActions={
          <>
            <button
              className="btn btn--grey"
              onClick={() => setIsModalOpen(false)}
            >
              {t('global.cancel')}
            </button>

            <button
              className="btn btn--socialie"
              data-loading={isLoading}
              disabled={isLoading}
              onClick={handleReactivate}
            >
              {t('settings.subscription.plan.reactivateModal.continue')}
            </button>
          </>
        }
      >
        {subscription.status === 'cancelled' ? (
          <Trans
            i18nKey="settings.subscription.plan.reactivateModal.bodyImmediate"
            values={{
              price: formatter.format(subscription.planAmount / 100),
              planName: subscription.plan.name,
              cardType: t(
                `settings.subscription.billing.cardType.${subscription.card.cardType}`
              ),
              last4: subscription.card.last4,
            }}
            components={{ b: <strong /> }}
          />
        ) : (
          t('settings.subscription.plan.reactivateModal.bodyEndOfTerm', {
            termEnd: formatDate(
              add(subscription.currentTermEnd || subscription.trialEnd || -1, {
                days: 1,
              }),
              {
                time: false,
                month: 'long',
              }
            ),
          })
        )}
      </Modal>
    </>
  );
}
