import { AnimatePresence, motion } from 'framer-motion';
import { IconCaretDown } from 'icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const QUESTION_COUNT = 3;

export default function SignUpPaymentFAQ() {
  const { t } = useTranslation();

  return (
    <div>
      <h4 className="h4 text-center border-b-default pb-6 mb-3">
        {t('onboarding.signUp.payment.faq.heading')}
      </h4>

      {[...Array(QUESTION_COUNT)].map((_, idx) => (
        <Question key={idx} index={idx} />
      ))}
    </div>
  );
}

interface QuestionProps {
  index: number;
}

function Question({ index }: QuestionProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((isOpen) => !isOpen);

  return (
    <div className="pb-3 mb-3 border-b-default">
      <div
        className="flex items-center justify-between"
        role="button"
        onClick={toggle}
      >
        <span className="h6" onClick={toggle}>
          {t(`onboarding.signUp.payment.faq.questions.${index}.title`)}
        </span>

        <motion.span
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.2 }}
          className="w-3 h-3 text-dark"
        >
          <IconCaretDown className="block w-full h-full" />
        </motion.span>
      </div>

      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            className="pt-3"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1 },
              collapsed: { opacity: 0 },
            }}
            transition={{ duration: 0.2 }}
          >
            {t(`onboarding.signUp.payment.faq.questions.${index}.answer`)}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
