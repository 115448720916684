import { rgba } from 'polished';
import { IconCaretLeft, IconCompanyPlaceholder, IconVideo } from 'icons';
import React from 'react';
import mediaTypeIcons from 'icons/mediaTypeIcons';
import { useTranslation } from 'react-i18next';
import orientationIcons from 'icons/orientationIcons';
import { FanRequestPreviewProps } from '../FanRequestPreview';

interface Props {
  values: FanRequestPreviewProps['values'];
  fanAccount: FanRequestPreviewProps['fanAccount'];
  coverPhotoUrl?: string | null;
  showModal: boolean;
}

export default function FanRequestPreviewDetails(props: Props) {
  const { values, fanAccount, coverPhotoUrl, showModal } = props;
  const { t } = useTranslation();

  return (
    <div className="w-full h-full relative overflow-hidden">
      <div
        className="absolute inset-0 z-10 bg-center bg-cover bg-black transform scale-110"
        style={{
          backgroundImage: `url('${coverPhotoUrl?.replace(/'/g, "\\'")}')`,
          filter: coverPhotoUrl
            ? `blur(${values.backgroundBlurAmount})`
            : undefined,
        }}
      />

      <div
        className="absolute inset-0 z-20 p-1.5"
        style={{
          backgroundColor: `${rgba(
            values.backgroundColor,
            values.backgroundOverlayOpacity * 1
          )}`,
        }}
      >
        {/* Navigation Bar */}
        <div className="flex items-center mb-3 px-0.5 space-x-1">
          <IconCaretLeft className="block w-3 h-3 text-white" />

          <div className="rounded-full w-4 h-4 bg-greyBg">
            {fanAccount.logoUrl ? (
              <img
                src={fanAccount.logoUrl}
                alt="Logo"
                className="rounded-full w-4 h-4"
              />
            ) : (
              <div className="p-0.75">
                <IconCompanyPlaceholder className="w-full h-full text-lightIcon" />
              </div>
            )}
          </div>

          <span className="text-white text-14">{fanAccount.name}</span>
        </div>
        {/* End Navigation Bar */}

        <div
          className="bg-white p-3"
          style={{ borderRadius: fanAccount.cardBorderRadius }}
        >
          {/* Subject */}
          <div
            className="text-20 font-bold leading-24 mb-1"
            style={{ color: fanAccount.headlineTextColor }}
          >
            {values.subject || t('fanRequestBuilder.form.subject.placeholder')}
          </div>
          {/* End Subject */}

          {/* Media Type and Orientation Pills */}
          <div className="flex items-center mb-2 space-x-1">
            {values.mediaType && (
              <Pill
                icon={mediaTypeIcons[values.mediaType]}
                text={t(`requestPreview.mediaType.${values.mediaType}`, {
                  context: values.videoDuration,
                })}
              />
            )}

            {values.orientation && (
              <Pill
                icon={orientationIcons[values.orientation]}
                text={t(`requestPreview.orientation.${values.orientation}`, {
                  context: values.videoDuration,
                })}
              />
            )}
          </div>
          {/* End Media Type and Orientation Pills */}

          {/* Instructions */}
          <div style={{ color: fanAccount.bodyTextColor }}>
            {values.instructions ||
              t('fanRequestBuilder.form.instructions.placeholder')}
          </div>
          {/* End Instructions */}
        </div>
      </div>

      {showModal && <Modal values={values} fanAccount={fanAccount} />}
    </div>
  );
}

interface PillProps {
  icon?: typeof IconVideo;
  text: string;
}

function Pill({ icon, text }: PillProps) {
  return (
    <div className="h-2.5 px-1 inline-flex items-center space-x-0.5 font-body rounded-full bg-white border-default">
      {icon &&
        React.createElement(icon, {
          className: 'w-1.75 h-1.75 flex-shrink-0 text-dark',
        })}

      <div className="text-12 font-bold text-dark">{text}</div>
    </div>
  );
}

function Modal(props: Pick<Props, 'values' | 'fanAccount'>) {
  const { values, fanAccount } = props;
  const { t } = useTranslation();

  return (
    <div className="absolute z-30 inset-0 bg-grey2 bg-opacity-70 px-2 flex items-center justify-center hide-scrollbar">
      <div
        className="p-4 w-full relative text-center bg-white mt-4"
        style={{ borderRadius: fanAccount.cardBorderRadius }}
      >
        <div className="absolute-h-center -top-4 w-8 h-8 rounded-full bg-greyBg">
          {fanAccount.logoUrl ? (
            <img
              src={fanAccount.logoUrl}
              alt="Logo"
              className="w-full h-full rounded-full"
            />
          ) : (
            <div className="p-1.5">
              <IconCompanyPlaceholder className="w-full h-full text-lightIcon" />
            </div>
          )}
        </div>

        <div className="mt-4 mb-1.5">
          <h3
            className="text-24 text-center leading-28 font-bold"
            style={{ color: fanAccount.headlineTextColor }}
          >
            {values.introHeader ||
              t('fanRequestBuilder.form.introHeader.placeholder')}
          </h3>
        </div>

        <div className="mb-4.5">
          <h3
            className="text-14 text-center leading-20"
            style={{ color: fanAccount.bodyTextColor }}
          >
            {values.introDescription ||
              t('fanRequestBuilder.form.introDescription.placeholder')}
          </h3>
        </div>

        <button
          type="button"
          className="w-full btn"
          style={{
            backgroundColor: fanAccount.buttonBackgroundColor,
            color: fanAccount.buttonTextColor,
            borderRadius: fanAccount.buttonBorderRadius,
          }}
        >
          <span>
            {values.introButtonText ||
              t('fanRequestBuilder.form.introButtonText.placeholder')}
          </span>
        </button>
      </div>
    </div>
  );
}
