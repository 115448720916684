import { useTranslation } from 'react-i18next';
import castArray from 'lodash/castArray';

export default function useTranslateErrors() {
  const { t } = useTranslation();

  const translateErrors = (errors?: string | string[] | null) => {
    const errorsArr = castArray(errors).filter(Boolean) as string[];
    return errorsArr.map((e) => t(e));
  };

  return translateErrors;
}
