import { useDeleteFanAccountUserMutation } from 'api/fanAccountUser';
import Modal from 'components/Modal';
import useFlashMessages from 'hooks/useFlashMessages';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FanAccountUser from 'types/FanAccountUser';

interface Props {
  fanAccountUser: FanAccountUser;
}

export default function SettingsUsersRemoveUser(props: Props) {
  const { fanAccountUser } = props;
  const isPending = fanAccountUser.pending;
  const { t } = useTranslation();
  const { addFlashMessage } = useFlashMessages();
  const [isOpen, setIsOpen] = useState(false);
  const [deleteFanAccountUser, { isLoading }] =
    useDeleteFanAccountUserMutation();

  const handleDelete = async () => {
    const result = await deleteFanAccountUser({ id: fanAccountUser.id });
    if ('error' in result) {
      addFlashMessage(t('global.unexpectedError'), { isError: true });
    } else {
      addFlashMessage(t('settings.users.remove.success'));
      setIsOpen(false);
    }
  };
  return (
    <>
      <button
        className="ml-1 underline hover:text-grey4 disabled:opacity-50"
        onClick={() => setIsOpen(true)}
        disabled={isLoading}
      >
        {t(`settings.users.remove.${isPending ? 'buttonPending' : 'button'}`)}
      </button>

      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        renderHeading={t(
          `settings.users.remove.${
            isPending ? 'modalHeadingPending' : 'modalHeading'
          }`
        )}
        renderActions={
          <>
            <button className="btn btn--grey" onClick={() => setIsOpen(false)}>
              {t('global.cancel')}
            </button>

            <button
              className="btn btn--socialie"
              onClick={handleDelete}
              data-loading={isLoading}
              disabled={isLoading}
            >
              {t(
                `settings.users.remove.${
                  isPending ? 'confirmPending' : 'confirm'
                }`
              )}
            </button>
          </>
        }
      >
        {t(
          `settings.users.remove.${
            isPending ? 'modalBodyPending' : 'modalBody'
          }`,
          { email: fanAccountUser.user.email }
        )}
      </Modal>
    </>
  );
}
