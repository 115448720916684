import { useState } from 'react';
import Modal from 'components/Modal';
import { useUpdateFanRequestMutation } from 'api/fanRequests';
import Form from 'components/Form';
import FanRequest from 'types/FanRequest';
import { useTranslation } from 'react-i18next';
import { addHours, setMilliseconds } from 'date-fns';
import useFlashMessages from 'hooks/useFlashMessages';
import { useEffect } from 'react';
import RequestLimitModal from 'components/RequestLimitModal';

interface Props {
  fanRequest: FanRequest;
  isOpen: boolean;
  onRequestClose: () => void;
}

type CheckedSelection = 'date' | 'now' | 'never';

function EditExpirationModal(props: Props) {
  const { fanRequest, isOpen, onRequestClose } = props;

  const { t } = useTranslation();
  const { addFlashMessage } = useFlashMessages();

  const [checked, setChecked] = useState<CheckedSelection>('date');

  const now = new Date();
  const oneHourFromNow = addHours(now, 1).getTime();

  const [expirationDate, setExpirationDate] = useState<number>(oneHourFromNow);

  const [isRequestLimitModalOpen, setIsRequestLimitModalOpen] = useState(false);

  useEffect(() => {
    if (!!fanRequest.expiresAt) {
      // Set initial date from fan request if it exists
      setChecked('date');
    } else {
      // Keep it null if its already null/doesn't exist
      setChecked('never');
    }
  }, [fanRequest.expiresAt]);

  const [updateFanRequest] = useUpdateFanRequestMutation();
  const handleSave = async (id: number, checkedSelection: CheckedSelection) => {
    let expiresAt;

    if (checkedSelection === 'now') {
      expiresAt = setMilliseconds(now, 0).getTime();
    } else if (checkedSelection === 'date') {
      expiresAt = setMilliseconds(expirationDate, 0).getTime();
    } else {
      expiresAt = null;
    }

    const result = await updateFanRequest({
      id,
      values: { expiresAt },
    });

    if ('error' in result) {
      if (
        'data' in result.error &&
        result.error.status === 422 &&
        result.error.data.errors.base?.[0] === 'validation.requestLimitReached'
      ) {
        setIsRequestLimitModalOpen(true);
      } else {
        addFlashMessage(t('global.unexpectedError'), {
          isError: true,
        });
      }
    } else {
      onRequestClose();
      addFlashMessage(t('fanRequestShow.topBar.expiresAt.modal.success'));
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        renderHeading={t('fanRequestShow.topBar.expiresAt.modal.heading')}
        renderActions={
          <div className="flex items-center justify-end space-x-1">
            <button
              className="btn btn--grey btn--inlineBlock"
              onClick={onRequestClose}
            >
              {t('global.cancel')}
            </button>
            <button
              className="btn btn--socialie btn--inlineBlock"
              onClick={() => handleSave(fanRequest.id, checked)}
            >
              {t('global.update')}
            </button>
          </div>
        }
      >
        <div className="mt-4">
          <div className="mb-1">
            <span className="text-14 text-dark font-bold">
              {t('fanRequestShow.topBar.expiresAt.modal.label')}
            </span>
          </div>
          <div className="mb-4.5 flex flex-col space-y-2">
            <div className="flex items-center space-x-1">
              <Form.Radio
                isChecked={checked === 'now'}
                onCheck={() => setChecked('now')}
              />
              <button
                onClick={() => setChecked('now')}
                className="inline text-14 text-dark"
              >
                {t('fanRequestShow.topBar.expiresAt.modal.optionOne')}
              </button>
            </div>

            <div className="flex items-center space-x-1">
              <Form.Radio
                isChecked={checked === 'never'}
                onCheck={() => setChecked('never')}
              />
              <button
                className="inline text-14 text-dark"
                onClick={() => setChecked('never')}
              >
                {t('fanRequestShow.topBar.expiresAt.modal.optionTwo')}
              </button>
            </div>

            <div className="flex flex-col">
              <div className="flex items-center space-x-1">
                <Form.Radio
                  isChecked={checked === 'date'}
                  onCheck={() => setChecked('date')}
                />
                <button
                  onClick={() => setChecked('date')}
                  className="inline text-14 text-dark"
                >
                  {t('fanRequestShow.topBar.expiresAt.modal.optionThree')}
                </button>
              </div>

              {checked === 'date' && (
                <Form.DateTimePicker
                  value={expirationDate}
                  onChange={(expiresAt) => setExpirationDate(expiresAt)}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>

      <RequestLimitModal
        isOpen={isRequestLimitModalOpen}
        onRequestClose={() => setIsRequestLimitModalOpen(false)}
      />
    </>
  );
}

export default EditExpirationModal;
