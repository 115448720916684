import { useTranslation } from 'react-i18next';
import fanSignUpBackgroundUrl from 'images/full.jpg';
import { IconCheck } from 'icons';

export default function SignUpTrialFeatures() {
  const { t } = useTranslation();

  return (
    <div className="hidden tablet:block h-full w-5/12 relative">
      <div className="block absolute -bottom-24 -left-12 z-40">
        <div className="w-48 h-48 bg-transparent border-12 border-solid border-socialiePink rounded-full" />
      </div>

      <img
        className="absolute inset-0 h-full w-full object-cover"
        src={fanSignUpBackgroundUrl}
        alt="fan signup background"
      />
      <div className="absolute inset-0 bg-grey1 bg-opacity-90 z-10" />
      <div className="absolute inset-0 px-3 z-40">
        <div className="max-w-55">
          <div className="flex flex-col pt-1/2">
            <div className="pb-3 mb-3 border-0 border-b border-white border-solid border-opacity-10">
              <h3 className="text-32 text-white leading-40 tracking-200 font-bold">
                {t('onboarding.signUp.marketingCopy.heading')}
              </h3>
            </div>
            <div className="mb-1.5">
              <p className="text-12 leading-16 text-white font-bold uppercase">
                {t('onboarding.signUp.marketingCopy.features.heading')}
              </p>
            </div>
          </div>
          <div className="flex flex-col space-y-1">
            <div className="flex items-center space-x-1">
              <IconCheck className="h-3 w-3 text-success" />
              <p className="text-16 leading-24 text-white">
                {t('onboarding.signUp.marketingCopy.features.first')}
              </p>
            </div>
            <div className="flex items-center space-x-1">
              <IconCheck className="h-3 w-3 text-success" />
              <p className="text-16 leading-24 text-white">
                {t('onboarding.signUp.marketingCopy.features.second')}
              </p>
            </div>
            <div className="flex items-center space-x-1">
              <IconCheck className="h-3 w-3 text-success" />
              <p className="text-16 leading-24 text-white">
                {t('onboarding.signUp.marketingCopy.features.third')}
              </p>
            </div>
            <div className="flex items-center space-x-1">
              <IconCheck className="h-3 w-3 text-success" />
              <p className="text-16 leading-24 text-white">
                {t('onboarding.signUp.marketingCopy.features.fourth')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
