import { IconCaretDown } from 'icons';
import cx from 'classnames';
import { useState } from 'react';

interface Props {
  title: string;
  initialState?: 'open' | 'closed';
  onClickAdd?: () => void;
  children: React.ReactNode;
}

export default function CollapsibleSection(props: Props) {
  const { title, children, initialState = 'open' } = props;
  const [isOpen, setIsOpen] = useState(initialState === 'open');

  return (
    <div>
      <button
        className="block w-full"
        onClick={() => setIsOpen((isOpen) => !isOpen)}
      >
        <span className="flex items-center justify-between mb-1 text-dark">
          <span className="flex-1 uppercase text-12 font-bold mr-2 truncate">
            {title}
          </span>
          <span className="flex items-center space-x-1">
            <IconCaretDown
              className={cx('block w-2 h-2 transition-default', {
                'transform -rotate-90': !isOpen,
              })}
            />
          </span>
        </span>
      </button>

      {isOpen && children}
    </div>
  );
}
