import { AnyAction } from 'redux';
import { RootState } from 'redux/store';

export default function loadingReducer(
  state: { [key: string]: boolean } = {},
  action: AnyAction
) {
  const { type } = action;
  const matches = /(.*)\/(pending|fulfilled|rejected)/.exec(type);

  if (!matches) return state;

  const [, requestName, requestState] = matches;

  let key = requestName;

  const id = getIdFromAction(action);
  if (id) key = `${key}:${id}`;

  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving users/fetch/pending
    // and false when receiving users/fetch/fulfilled or users/fetch/rejected
    [key]: requestState === 'pending',
  };
}

const getIdFromAction = (action: AnyAction) => {
  const arg = action.meta?.arg;
  if (!arg) return;
  if (arg.id) return arg?.id;
  if (typeof arg === 'number' || typeof arg === 'string') return arg;
};

// Selectors
export const getIsLoading = (state: RootState, key: string, id?: any) => {
  let lookup = key;
  if (id) lookup = `${lookup}:${id}`;
  return state.loading[lookup];
};
