import FanRequest from 'types/FanRequest';
import FanRequestType from 'types/FanRequestType';

export default function getTypeFromFanRequest(
  fanRequest?: FanRequest
): FanRequestType {
  if (!fanRequest) return 'live';
  if (fanRequest.status === 'draft') return 'draft';
  if (fanRequest.expiresAt && fanRequest.expiresAt < new Date().getTime()) {
    return 'expired';
  }
  return 'live';
}
