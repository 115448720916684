import routes from 'helpers/routes';
import { useHistory } from 'react-router-dom';
import { IconPlus } from 'icons';
import { useTranslation } from 'react-i18next';
import { useCreateFanRequestMutation } from 'api/fanRequests';
import routeFor from 'helpers/routeFor';

interface Props {
  renderButton?: (onClick: () => void, isLoading: boolean) => React.ReactNode;
}

export default function NewRequestButton(props: Props) {
  const { renderButton } = props;
  const { t } = useTranslation();
  const [createFanRequest, { isLoading }] = useCreateFanRequestMutation();
  const history = useHistory();

  const handleCreate = async () => {
    const result = await createFanRequest({});

    if ('data' in result) {
      if ('errors' in result.data) {
        // do errors
      } else {
        history.push(routeFor(routes.requests.build, 'draft', result.data.id));
      }
    } else {
      // do errors
    }
  };

  if (renderButton) return <>{renderButton(handleCreate, isLoading)}</>;

  return (
    <button
      onClick={handleCreate}
      data-loading={isLoading}
      disabled={isLoading}
      className="btn btn--outline btn--small mr-2 inline-block w-auto"
    >
      <span className="flex items-center">
        <IconPlus />
        <span>{t('globalTopBar.newRequest')}</span>
      </span>
    </button>
  );
}
