import { SecondaryTopBar } from 'components/Layout/components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/reducers/auth';
import {
  SettingsProfileAvatar,
  SettingsProfileEmail,
  SettingsProfileName,
  SettingsProfilePassword,
} from './components';

export default function SettingsProfile() {
  const { t } = useTranslation();
  const currentUser = useSelector(selectCurrentUser);

  if (!currentUser) return null;

  return (
    <>
      <SecondaryTopBar heading={t('settings.profile.heading')} />
      <div className="pt-17">
        <div className="w-76 max-w-full mx-auto p-2">
          <SettingsProfileAvatar currentUser={currentUser} />
          <SettingsProfileName currentUser={currentUser} />
          <SettingsProfileEmail currentUser={currentUser} />
          <SettingsProfilePassword />
        </div>
      </div>
    </>
  );
}
