import api from './index';
import camelize from 'camelize';
import FanAccountUser from 'types/FanAccountUser';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchAccountUsers: builder.query<FanAccountUser[], void>({
      query: () => ({ url: 'account/users' }),
      transformResponse: camelize,
      providesTags: (result, error, arg) =>
        result?.length
          ? result.map((fanAccountUser) => ({
              type: 'FanAccountUser',
              id: fanAccountUser.id,
            }))
          : ['FanAccountUser'],
    }),

    inviteAccountUser: builder.mutation<
      FanAccountUser,
      { email: string; role: string }
    >({
      query: (params) => ({
        url: 'account/users',
        method: 'POST',
        body: params,
      }),
      transformResponse: camelize,
      invalidatesTags: ['FanAccountUser', 'FanPlan'],
    }),

    resendFanAccountUserInvite: builder.mutation<
      { success: boolean },
      { id: number }
    >({
      query: ({ id }) => ({
        url: `account/users/${id}/resend-invite`,
        method: 'POST',
      }),
      transformResponse: camelize,
      invalidatesTags: (result, error, arg) => [
        { type: 'FanAccountUser', id: arg.id },
      ],
    }),

    updateFanAccountUser: builder.mutation<
      FanAccountUser,
      { id: number; role: string }
    >({
      query: ({ id, role }) => ({
        url: `account/users/${id}`,
        method: 'PUT',
        body: { role },
      }),
      transformResponse: camelize,
      invalidatesTags: (result, error, arg) => [
        { type: 'FanAccountUser', id: arg.id },
      ],
    }),

    deleteFanAccountUser: builder.mutation<{ success: true }, { id: number }>({
      query: ({ id }) => ({
        url: `account/users/${id}`,
        method: 'DELETE',
      }),
      transformResponse: camelize,
      invalidatesTags: (result, error, arg) => [
        { type: 'FanAccountUser', id: arg.id },
        'FanPlan',
      ],
    }),
  }),
});

export default extendedApi;

export const {
  useFetchAccountUsersQuery,
  useResendFanAccountUserInviteMutation,
  useInviteAccountUserMutation,
  useDeleteFanAccountUserMutation,
  useUpdateFanAccountUserMutation,
} = extendedApi;
