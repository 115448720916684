import { useTranslation } from 'react-i18next';
import Usage from 'components/Usage';
import SettingsSubscriptionSection from './SettingsSubscriptionSection';

export default function SettingsSubscriptionUsage() {
  const { t } = useTranslation();

  return (
    <SettingsSubscriptionSection
      heading={t('settings.subscription.usage.heading')}
    >
      <div className="mt-3 mb-2 flex items-center space-x-1">
        <Usage.Requests />
        <Usage.UsersPill />
      </div>

      <div className="text-dark mb-2 last:mb-0">
        <div className="text-14 font-bold mb-0.5">
          {t('settings.subscription.usage.storage')}
        </div>
        <Usage.Storage />
      </div>

      <div className="text-dark mb-1.5 last:mb-0">
        <div className="text-14 font-bold mb-0.5">
          {t('settings.subscription.usage.submissions')}
        </div>
        <Usage.Submissions />
      </div>
    </SettingsSubscriptionSection>
  );
}
