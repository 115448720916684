import cx from 'classnames';

interface Props {
  value?: React.ComponentProps<'input'>['value'];
  maxLength: number;
}

export default function SuggestionCaptionRemainingChars(props: Props) {
  const { maxLength } = props;
  const value = `${props.value || ''}`;
  const remainingChars = maxLength - value.length;

  let style = 'normal';
  if (remainingChars < maxLength - maxLength * 0.8) style = 'warning';
  if (remainingChars < 0) style = 'error';

  return (
    <div
      className={cx(
        'inline-block transition-default pl-0.75 font-bold text-14',
        {
          'text-light': style === 'normal',
          'text-error': style === 'error',
          'text-yellow': style === 'warning',
        }
      )}
    >
      <span className="bump-up-0.5">{remainingChars}</span>
    </div>
  );
}
