import { useTranslation } from 'react-i18next';

interface Props {
  label?: string;
  buttonText?: string;
  onClick?: () => void;
  children: React.ReactNode;
}

export default function SettingsProfileSection(props: Props) {
  const { t } = useTranslation();
  const { label, buttonText = t('global.edit'), onClick, children } = props;

  return (
    <div className="py-4 border-b-default last:border-b-0">
      {label && (
        <div className="text-18 leading-20 font-black text-dark">{label}</div>
      )}

      {children}

      {onClick && (
        <button
          className="mt-2 bg-grey8 rounded px-1 py-0.5 text-dark text-12 font-black"
          onClick={onClick}
        >
          {buttonText}
        </button>
      )}
    </div>
  );
}
