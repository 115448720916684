import { useInviteAccountUserMutation } from 'api/fanAccountUser';
import Btn from 'components/Btn';
import Form from 'components/Form';
import Modal from 'components/Modal';
import Usage from 'components/Usage';
import { AnimatePresence, motion } from 'framer-motion';
import useFlashMessages from 'hooks/useFlashMessages';
import usePlan from 'hooks/usePlan';
import { IconArrowRight, IconSend } from 'icons';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onContinue: () => void;
}

export default function OnboardingUsers(props: Props) {
  const { onContinue } = props;
  const successTimer = useRef<number | null>(null);
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [lastEmail, setLastEmail] = useState('');
  const [errors, setErrors] = useState<string | null>(null);
  const [didSend, setDidSend] = useState(false);
  const [inviteAccountUser, { isLoading }] = useInviteAccountUserMutation();
  const { addFlashMessage } = useFlashMessages();
  const plan = usePlan();
  const isAtLimit = plan.maxUsers && plan.usage.userCount >= plan.maxUsers;

  const handleSendInvite = async () => {
    if (successTimer.current) window.clearTimeout(successTimer.current);
    setErrors(null);
    const result = await inviteAccountUser({ email, role: 'member' });
    if ('error' in result) {
      if ('data' in result.error && result.error.status === 422) {
        setErrors(result.error.data.errors);
      } else {
        addFlashMessage(t('global.unexpectedError'), { isError: true });
      }
    } else {
      setDidSend(true);
      setLastEmail(email);
      setEmail('');
      successTimer.current = window.setTimeout(() => setLastEmail(''), 6000);
    }
  };

  return (
    <Modal
      isOpen
      onRequestClose={() => {}}
      theme="onboarding"
      renderHeading={t('onboarding.users.heading')}
      renderActions={
        <>
          {didSend ? (
            <Btn
              variant="inlineBlock"
              rightIcon={IconArrowRight}
              onClick={onContinue}
            >
              {t('global.continue')}
            </Btn>
          ) : (
            <button
              className="text-socialiePink text-14 font-bold hover:underline"
              onClick={onContinue}
            >
              {t('onboarding.users.skip')}
            </button>
          )}
        </>
      }
    >
      <>
        <div className="text-dark text-14 mb-4">
          {t('onboarding.users.description')}
        </div>

        <div className="max-w-44 mx-auto">
          <div className="pb-2 mb-2.5 border-b-default">
            {!isAtLimit ? (
              <Form onSubmit={handleSendInvite}>
                <div className="flex items-start mb-1">
                  <div className="flex-1 mr-0.5 text-left">
                    <Form.TextInput
                      value={email}
                      errors={errors ? t(errors) : undefined}
                      onChange={(e) => setEmail(e.target.value)}
                      label={t('onboarding.users.label')}
                      placeholder={t('onboarding.users.placeholder')}
                      type="email"
                    />
                  </div>

                  <Btn
                    color="outline"
                    variant="inlineBlock"
                    className="mt-3.5"
                    leftIcon={IconSend}
                    isLoading={isLoading}
                    disabled={!email}
                    type="submit"
                  >
                    {t('onboarding.users.invite')}
                  </Btn>
                </div>
              </Form>
            ) : (
              <div className="notice notice--callout mb-1">
                {t('onboarding.users.limitReached')}
              </div>
            )}

            <AnimatePresence>
              {!!lastEmail && (
                <motion.div
                  className="notice notice--success mb-0"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.15 }}
                >
                  {t('onboarding.users.success', { email: lastEmail })}
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <div className="px-4">
            <Usage.UsersPill size="normal" />
          </div>
        </div>
      </>
    </Modal>
  );
}
