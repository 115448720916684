import api from './index';
import camelize from 'camelize';
import Domain from 'types/Domain';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchDomains: builder.query<Domain[], void>({
      query: () => ({ url: 'domains' }),
      transformResponse: camelize,
      providesTags: ['FanDomain'],
    }),

    createDomain: builder.mutation<Domain, string>({
      query: (domain) => ({
        url: 'domains',
        method: 'POST',
        body: { domain },
      }),
      transformResponse: camelize,
      invalidatesTags: ['FanDomain'],
    }),

    deleteDomain: builder.mutation<void, number>({
      query: (id) => ({ url: `domains/${id}`, method: 'DELETE' }),
      invalidatesTags: ['FanDomain'],
    }),

    checkDomainDns: builder.mutation<Domain, number>({
      query: (id) => ({ url: `domains/${id}/check-dns`, method: 'POST' }),
      invalidatesTags: ['FanDomain'],
      transformResponse: camelize,
    }),

    checkDomainSsl: builder.mutation<Domain, number>({
      query: (id) => ({ url: `domains/${id}/check-ssl`, method: 'POST' }),
      invalidatesTags: ['FanDomain'],
      transformResponse: camelize,
    }),
  }),
});

export default extendedApi;

export const {
  useCreateDomainMutation,
  useFetchDomainsQuery,
  useDeleteDomainMutation,
  useCheckDomainDnsMutation,
  useCheckDomainSslMutation,
} = extendedApi;
