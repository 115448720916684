import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useSelectableContext } from 'hooks/useSelectable';
import FanSubmission from 'types/FanSubmission';
import FanSubmissionThumbnail from './FanSubmissionThumbnail/FanSubmissionThumbnail';
import Loader from 'components/Loader';
import RenderIfVisible from 'components/RenderIfVisible';
import FanSubmissionThumbnailContainer from './FanSubmissionThumbnailContainer';
import FanRequest from 'types/FanRequest';

interface Props {
  fanSubmissions: FanSubmission[];
  fanRequest: FanRequest;
  isFetching: boolean;
  loadNextPage: () => void;
  hasNextPage: boolean;
}

export default function FanSubmissionList(props: Props) {
  const { fanSubmissions, fanRequest, isFetching, loadNextPage, hasNextPage } =
    props;
  const { selectedIds, toggleSelected } = useSelectableContext();
  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage,
    onLoadMore: hasNextPage ? loadNextPage : () => {},
    disabled: !fanSubmissions.length,
  });

  return (
    <>
      <div className="fan-submission-list">
        {fanSubmissions.map((fanSubmission) => (
          <RenderIfVisible
            key={fanSubmission.id}
            renderPlaceholder={() => (
              <FanSubmissionThumbnailContainer
                width={fanSubmission.width}
                height={fanSubmission.height}
              />
            )}
          >
            <FanSubmissionThumbnail
              fanRequest={fanRequest}
              fanSubmission={fanSubmission}
              isSelectMode={!!selectedIds.length}
              isSelected={selectedIds.includes(fanSubmission.id)}
              onToggleSelected={() => toggleSelected(fanSubmission.id)}
            />
          </RenderIfVisible>
        ))}
      </div>

      {(hasNextPage || isFetching) && (
        <div ref={sentryRef}>
          <Loader />
        </div>
      )}
    </>
  );
}
