import { useState } from 'react';
import camelize from 'camelize';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal';
import Form from 'components/Form';
import useFlashMessages from 'hooks/useFlashMessages';
import SettingsProfileSection from './SettingsProfileSection';
import { useChangePasswordMutation } from 'api/user';
import ValidationErrorPayload from 'types/ValidationErrorPayload';
import useTranslateErrors from 'hooks/useTranslateErrors';

const defaultValues = {
  currentPassword: '',
  password: '',
  passwordConfirmation: '',
};

export default function SettingsProfilePassword() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState<ValidationErrorPayload<
    typeof values
  > | null>(null);
  const { addFlashMessage } = useFlashMessages();
  const translateErrors = useTranslateErrors();

  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const handleCancel = () => {
    setValues(defaultValues);
    setErrors(null);
    setIsOpen(false);
  };

  const handleSubmit = async () => {
    setErrors(null);
    const result = await changePassword(values);

    if ('error' in result) {
      if ('data' in result.error && result.error.status === 422) {
        setErrors(camelize(result.error.data.errors));
      } else {
        addFlashMessage(t('global.unexpectedError'), { isError: true });
      }
    } else {
      addFlashMessage(t('settings.profile.password.success'));
      setValues(defaultValues);
      setIsOpen(false);
    }
  };

  return (
    <>
      <SettingsProfileSection
        label={t('settings.profile.password.label')}
        onClick={() => setIsOpen(true)}
        buttonText={t('settings.profile.password.buttonText')}
      >
        <div className="text-dark text-14">
          {t('settings.profile.password.description')}
        </div>
      </SettingsProfileSection>

      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        renderHeading={t('settings.profile.password.modal.heading')}
        renderActions={
          <>
            <button className="btn btn--grey" onClick={handleCancel}>
              {t('global.cancel')}
            </button>

            <button
              className="btn btn--socialie"
              onClick={handleSubmit}
              disabled={isLoading || Object.values(values).includes('')}
              data-loading={isLoading}
            >
              {t('global.save')}
            </button>
          </>
        }
      >
        <Form onSubmit={handleSubmit}>
          <Form.TextInput
            label={t('settings.profile.password.modal.currentPassword.label')}
            value={values.currentPassword}
            type="password"
            errors={translateErrors(errors?.currentPassword)}
            onChange={(e) =>
              setValues((values) => ({
                ...values,
                currentPassword: e.target.value,
              }))
            }
          />

          <Form.TextInput
            label={t('settings.profile.password.modal.password.label')}
            value={values.password}
            type="password"
            errors={translateErrors(errors?.password)}
            onChange={(e) =>
              setValues((values) => ({ ...values, password: e.target.value }))
            }
          />

          <Form.TextInput
            label={t(
              'settings.profile.password.modal.passwordConfirmation.label'
            )}
            value={values.passwordConfirmation}
            type="password"
            errors={translateErrors(errors?.passwordConfirmation)}
            onChange={(e) =>
              setValues((values) => ({
                ...values,
                passwordConfirmation: e.target.value,
              }))
            }
          />
        </Form>
      </Modal>
    </>
  );
}
