import camelize from 'camelize';
import CurrentUserApiPayload from 'types/CurrentUserApiPayload';
import Invitation from 'types/Invitation';
import api from './index';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchInvitation: builder.query<Invitation, { code: string }>({
      query: ({ code }) => ({ url: `invitations/${code}` }),
      transformResponse: camelize,
    }),

    acceptInvitation: builder.mutation<
      CurrentUserApiPayload,
      { code: string; password?: string; passwordConfirmation?: string }
    >({
      query: ({ code, password, passwordConfirmation }) => ({
        url: `invitations/${code}/accept`,
        body: { password, passwordConfirmation },
        method: 'POST',
      }),
      transformResponse: camelize,
    }),
  }),
});

export default extendedApi;

export const { useFetchInvitationQuery, useAcceptInvitationMutation } =
  extendedApi;
