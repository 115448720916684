import Modal from 'components/Modal';
import { IconZoomIn, IconZoomOut } from 'icons';
import { useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  onCrop: (dataUrl: string) => void;
  src?: string | null;
  contentType?: string | null;
  aspectRatio: number;
  borderRadius: number;
}

export default function FileInputCropModal(props: Props) {
  const editorRef = useRef<AvatarEditor>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    isOpen,
    onRequestClose,
    src,
    onCrop,
    aspectRatio,
    borderRadius,
    contentType,
  } = props;
  const { t } = useTranslation();
  const [geometry, setGeometry] = useState({
    position: { x: 0.5, y: 0.5 },
    currentScale: 1.2,
  });

  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (isOpen && wrapperRef.current) {
      setWidth(wrapperRef.current.offsetWidth);
    }
  }, [isOpen]);

  const handleCrop = () => {
    if (!editorRef.current) return;
    const canvas = editorRef.current.getImage();
    onCrop(canvas.toDataURL(contentType || 'image/jpeg', 0.8));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      renderHeading={t('form.fileInput.cropModal.heading')}
      contentClassNames="!p-0"
      renderActions={
        <>
          <button
            className="btn btn--grey btn--inlineBlock"
            onClick={onRequestClose}
          >
            {t('global.cancel')}
          </button>

          <button
            className="btn btn--socialie btn--inlineBlock"
            onClick={handleCrop}
          >
            {t('form.fileInput.cropModal.apply')}
          </button>
        </>
      }
    >
      <div ref={wrapperRef}>
        {src && !!width && (
          <>
            <div className="relative mb-2">
              <AvatarEditor
                ref={editorRef as any}
                image={src}
                onPositionChange={(position) =>
                  setGeometry({ ...geometry, position })
                }
                width={width - 48}
                height={(width - 48) / aspectRatio}
                borderRadius={borderRadius}
                border={24}
                position={geometry.position}
                disableBoundaryChecks={false}
                color={[255, 255, 255, 0.5]} // RGBA
                scale={geometry.currentScale}
              />

              <div
                style={{ borderRadius: `${borderRadius}px` }}
                className="absolute top-2.5 left-2.5 bottom-2.5 right-2.5 border-4 border-solid border-brightBlue pointer-events-none"
              />
            </div>

            <div className="flex items-center px-12">
              <IconZoomOut className="w-2.5 h-2.5 text-light" />
              <input
                type="range"
                className="range-input flex-1 mx-1"
                min="1"
                max="5"
                step={0.1}
                value={geometry.currentScale}
                onChange={(e) =>
                  setGeometry((geometry) => ({
                    ...geometry,
                    currentScale: e.target.valueAsNumber,
                  }))
                }
              />
              <IconZoomIn className="w-2.5 h-2.5 text-light" />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
