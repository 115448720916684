import React from 'react';

interface Props {
  heading: string;
  subHeading?: string;
  children?: React.ReactNode;
}

function SecondaryTopBar(props: Props) {
  const { heading, subHeading, children } = props;

  return (
    <div className="fixed left-30 right-0 top-7 z-50">
      <div className="flex items-center h-7 border-b-default bg-white px-2">
        <div className="flex-1 flex flex-col justify-center">
          <h1 className="h4 leading-none">{heading}</h1>
          {!!subHeading && (
            <p className="text-12 leading-16 text-grey5">{subHeading}</p>
          )}
        </div>
        {children}
      </div>
    </div>
  );
}

export default SecondaryTopBar;
