import React, { useState, useLayoutEffect, useRef, forwardRef } from 'react';
import cx from 'classnames';

interface Props {
  srcSet: string;
  preview: string;
  preventLoading?: boolean;
  className?: string;
  imgClassName?: string;
  style?: React.ComponentProps<'div'>['style'];
  objectFit?: 'cover' | 'contain' | 'natural';
  imgProps?: React.ComponentProps<'img'>;
  onLoad?: (img: HTMLImageElement) => void;
  onLoadPreview?: () => void;
}

const BlurUp = forwardRef<HTMLImageElement, Props>((props, ref) => {
  const {
    preview,
    srcSet,
    preventLoading,
    objectFit,
    onLoad,
    onLoadPreview,
    style,
    className,
    imgClassName,
    imgProps,
  } = props;
  const [liveSrcSet, setLiveSrcSet] = useState(preview);
  const img = useRef(new Image());

  useLayoutEffect(() => {
    if (preventLoading || srcSet === liveSrcSet) return;

    const imgObj = img.current;

    const showHighQualityImage = () => {
      setLiveSrcSet(srcSet);
      onLoad?.(imgObj);
    };

    imgObj.srcset = srcSet;

    if (imgObj.complete) {
      showHighQualityImage();
      return;
    }

    imgObj.addEventListener('load', showHighQualityImage);

    return () => {
      imgObj.removeEventListener('load', showHighQualityImage);
    };
  }, [preview, liveSrcSet, preventLoading, srcSet, onLoad]);

  const shouldBlur = liveSrcSet === preview;

  return (
    <div
      className={cx(
        'relative overflow-hidden',
        { 'h-full': !!objectFit },
        className
      )}
      style={style}
    >
      <img
        ref={ref}
        srcSet={liveSrcSet}
        onLoad={() => {
          if (liveSrcSet === preview) onLoadPreview?.();
        }}
        className={cx(
          {
            'w-full': !objectFit,
            'w-full h-full object-contain': objectFit === 'contain',
            'w-full h-full object-cover': objectFit === 'cover',
            'w-auto': objectFit === 'natural',
          },
          imgClassName
        )}
        style={{
          filter: shouldBlur ? 'blur(10px)' : 'none',
          transition: shouldBlur ? 'none' : 'filter 0.2s ease-out',
        }}
        alt=""
        {...imgProps}
      />
    </div>
  );
});

export default BlurUp;
