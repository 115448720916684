import { useEffect } from 'react';
import { Prompt } from 'react-router-dom';
interface Props {
  msg: string;
}

export default function ProtectUnsavedChanges({ msg }: Props) {
  useEffect(() => {
    const preventUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', preventUnload);
    return () => {
      window.removeEventListener('beforeunload', preventUnload);
    };
  }, []);

  return <Prompt message={msg} />;
}
