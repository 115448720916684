import cx from 'classnames';
import Errors from '../Errors';
import wordsToSentence from 'helpers/wordsToSentence';
import useDirectUpload from 'hooks/useDirectUpload';
import { IconPlaceholder, IconRemove, IconUpload } from 'icons';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileInputCropModal } from './components';
import Description from '../Description';
import { createElement } from 'react';

interface Props {
  extensions: string[];
  attachment?: {
    src?: string | null;
    contentType?: string | null;
    name?: string | null;
  };
  aspectRatio?: number;
  borderRadius?: number;
  errors?: string[];
  placeholderIcon?: typeof IconPlaceholder;
  thumbnailSize?: 'large' | 'small';
  onChange: (signedId: string | null) => void;
  onSelectFile?: (dataUrl: string | null) => void;
  onUploadStart?: () => void;
  onUploadSuccess?: (signedId: string) => void;
  onUploadFailure?: () => void;
}

export default function FileInput(props: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const {
    extensions,
    attachment,
    aspectRatio,
    borderRadius = 8,
    thumbnailSize = 'large',
    onChange,
    errors,
    onSelectFile,
    placeholderIcon = IconPlaceholder,
    ...callbacks
  } = props;
  const [currentAttachment, setCurrentAttachment] = useState(
    attachment || {
      src: null,
      contentType: '',
      name: '',
    }
  );
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const { startUpload, cancelUpload, uploadProgress, uploadStatus } =
    useDirectUpload({
      ...callbacks,
      onUploadSuccess: (signedId) => {
        onChange(signedId);
        callbacks.onUploadSuccess?.(signedId);
      },
    });

  const handleSelectFile = (file?: File | null) => {
    if (!file) {
      onSelectFile?.(null);
      return;
    }

    const src = URL.createObjectURL(file);
    onSelectFile?.(src);
    setCurrentAttachment({
      src,
      name: file.name,
      contentType: file.type,
    });

    if (aspectRatio) {
      setIsCropModalOpen(true);
    } else {
      startUpload(file);
    }
  };

  const handleCrop = async (dataUrl: string) => {
    setCurrentAttachment((currentAttachment) => ({
      ...currentAttachment,
      src: dataUrl,
    }));
    onSelectFile?.(dataUrl);
    setIsCropModalOpen(false);
    const data = await fetch(dataUrl);
    const blob = await data.blob();
    const file = blobToFile(blob, currentAttachment.name || '');
    startUpload(file);
  };

  const handleRemove = () => {
    if (uploadStatus === 'UPLOADING') cancelUpload();
    setCurrentAttachment({ src: null, name: '', contentType: '' });
    onChange(null);
    onSelectFile?.(null);
  };

  const handleCancelCrop = () => {
    setCurrentAttachment({ src: null, name: '', contentType: '' });
    onSelectFile?.(null);
    setIsCropModalOpen(false);
  };

  const thumbnailClassNames = cx('block w-full h-full', {
    'object-cover': !!aspectRatio,
    'object-contain': !aspectRatio,
  });

  return (
    <>
      <div className="flex items-center">
        <div
          style={{ borderRadius: `${borderRadius}px` }}
          className={cx(
            'flex-shrink-0 flex items-center justify-center overflow-hidden mr-2',
            {
              'bg-grey8': !currentAttachment.src,
              'bg-grey2': !!currentAttachment.src,
              'w-18 h-18': thumbnailSize === 'large',
              'w-12 h-12': thumbnailSize === 'small',
            }
          )}
        >
          {currentAttachment.src ? (
            <>
              {currentAttachment.contentType?.match(/video/) ? (
                <video
                  src={currentAttachment.src}
                  className={thumbnailClassNames}
                />
              ) : (
                <img
                  src={currentAttachment.src}
                  className={thumbnailClassNames}
                  alt=""
                />
              )}
            </>
          ) : (
            createElement(placeholderIcon, { className: 'text-grey7 w-9 h-9' })
          )}
        </div>
        <div>
          {/* Button */}
          {!currentAttachment.src ? (
            <>
              <button
                type="button"
                className="btn btn--small btn--socialie btn--inlineBlock"
                onClick={() => fileInputRef.current?.click()}
              >
                <span className="flex items-center">
                  <IconUpload />
                  <span>{t('form.fileInput.upload')}</span>
                </span>
              </button>

              <input
                ref={fileInputRef}
                type="file"
                onChange={(e) => handleSelectFile(e.target.files?.[0])}
                className="absolute"
                accept={extensions.map((e) => `.${e}`).join(',')}
                style={{ left: '-9999px' }}
              />
            </>
          ) : ['WAITING', 'FAILED', 'SUCCESS'].includes(uploadStatus) ? (
            <button
              type="button"
              className="btn btn--small btn--grey btn--inlineBlock"
              onClick={handleRemove}
            >
              <span className="flex items-center">
                <IconRemove />
                <span>{t('form.fileInput.remove')}</span>
              </span>
            </button>
          ) : uploadStatus === 'UPLOADING' ? (
            <button
              type="button"
              className="btn btn--small btn--grey btn--inlineBlock"
            >
              <span className="flex items-center text-light">
                <IconRemove />
                <span>
                  {t('form.fileInput.uploading')}... {uploadProgress}%
                </span>
              </span>
            </button>
          ) : null}

          {/* Messaging */}
          {!!errors?.length ? (
            <Errors errors={errors} />
          ) : uploadStatus === 'FAILED' ? (
            <Errors errors={t('form.fileInput.uploadFailed')} />
          ) : !currentAttachment.src ? (
            <Description>
              {wordsToSentence(
                extensions.map((e) => e.toUpperCase()),
                { connector: t('global.or') }
              )}
            </Description>
          ) : null}
        </div>
      </div>

      {!!aspectRatio && (
        <FileInputCropModal
          isOpen={isCropModalOpen}
          onRequestClose={handleCancelCrop}
          onCrop={handleCrop}
          src={currentAttachment.src}
          contentType={currentAttachment.contentType}
          aspectRatio={aspectRatio}
          borderRadius={borderRadius}
        />
      )}
    </>
  );
}

function blobToFile(theBlob: Blob, fileName: string) {
  const b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  //Cast to a File() type
  return theBlob as File;
}
