import api from './index';
import camelize from 'camelize';
import qs from 'qs';
import FanSubmission from 'types/FanSubmission';
import FanSubmissionFilter from 'types/FanSubmissionFilter';
import PaginatedResponse from 'types/PaginatedResponse';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchFanSubmissions: builder.query<
      PaginatedResponse<{ fanSubmissions: FanSubmission[] }>,
      { requestId: number; filter: FanSubmissionFilter; cursor: string }
    >({
      query: ({ requestId, filter, cursor }) => ({
        url: `requests/${requestId}/submissions?cursor=${cursor}&${qs.stringify(
          filter
        )}`,
      }),
      transformResponse: camelize,
    }),

    fetchFanSubmission: builder.query<
      FanSubmission,
      { id: number; filter: FanSubmissionFilter }
    >({
      query: ({ id, filter }) => ({
        url: `submissions/${id}?${qs.stringify(filter)}`,
      }),
      transformResponse: camelize,
    }),

    updateFanSubmission: builder.mutation<
      FanSubmission,
      {
        fanSubmission: FanSubmission;
        changes: { rating: FanSubmission['rating'] };
      }
    >({
      query: ({ fanSubmission, changes }) => ({
        url: `submissions/${fanSubmission.id}`,
        method: 'PUT',
        body: changes,
      }),
      transformResponse: camelize,
    }),

    bulkUpdateFanSubmissions: builder.mutation<
      { success: boolean },
      {
        fanSubmissions: FanSubmission[];
        changes: { rating: FanSubmission['rating'] };
      }
    >({
      query: ({ fanSubmissions, changes }) => ({
        url: `submissions/bulk_update`,
        method: 'PUT',
        body: { ids: fanSubmissions.map((f) => f.id), ...changes },
      }),
      transformResponse: camelize,
    }),

    bulkDeleteFanSubmissions: builder.mutation<
      { success: boolean },
      { fanSubmissions: FanSubmission[]; fanRequestId: number }
    >({
      query: ({ fanSubmissions }) => ({
        url: `submissions/bulk_destroy`,
        method: 'DELETE',
        body: { ids: fanSubmissions.map((f) => f.id) },
      }),
      transformResponse: camelize,
      invalidatesTags: (result, error, arg) => [
        { type: 'FanRequest', id: arg.fanRequestId },
      ],
    }),

    downloadFanSubmission: builder.query<
      { url: string },
      { id: FanSubmission['id'] }
    >({
      query: ({ id }) => ({ url: `submissions/${id}/download` }),
      transformResponse: camelize,
    }),

    bulkDownloadFanSubmissions: builder.mutation<
      { uuid: string },
      { ids: number[]; socketId: string | null }
    >({
      query: (params) => ({
        url: `submissions/bulk_download`,
        method: 'PUT',
        body: params,
      }),
      transformResponse: camelize,
    }),
  }),
});

export default extendedApi;

export const {
  useLazyFetchFanSubmissionsQuery,
  useLazyDownloadFanSubmissionQuery,
  useFetchFanSubmissionsQuery,
  useFetchFanSubmissionQuery,
  useUpdateFanSubmissionMutation,
  useBulkUpdateFanSubmissionsMutation,
  useBulkDeleteFanSubmissionsMutation,
  useBulkDownloadFanSubmissionsMutation,
} = extendedApi;
