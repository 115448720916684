import api from './index';
import camelize from 'camelize';
import Estimate from 'types/Estimate';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchEstimate: builder.query<Estimate, string>({
      query: (planId) => ({ url: `estimates/${planId}` }),
      transformResponse: camelize,
    }),
  }),
});

export default extendedApi;

export const { useFetchEstimateQuery } = extendedApi;
