import { useBulkDownloadFanSubmissionsMutation } from 'api/fanSubmissions';
import useFlashMessages from 'hooks/useFlashMessages';
import { useSocket, useSocketEvent } from 'hooks/useSocket';
import { IconDownload, IconLoader } from 'icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FanSubmission from 'types/FanSubmission';
import FanSubmissionBulkAction from './FanSubmissionBulkAction';

interface Props {
  fanSubmissions: FanSubmission[];
}

export default function FanSubmissionBulkDownload(props: Props) {
  const { fanSubmissions } = props;
  const { t } = useTranslation();
  const { socketId } = useSocket();
  const { addFlashMessage } = useFlashMessages();
  const [bulkDownloadUuid, setBulkDownloadUuid] = useState<string | null>(null);
  const [bulkDownloadFanSubmissions, { isLoading: isStartingDownload }] =
    useBulkDownloadFanSubmissionsMutation();

  useSocketEvent(
    'fanSubmissionZipReady',
    (params: { uuid: string; url: string }) => {
      if (params.uuid === bulkDownloadUuid) setBulkDownloadUuid(null);
      window.location.href = params.url;
    }
  );

  const handleDownload = async () => {
    const ids = fanSubmissions.map((f) => f.id);
    const result = await bulkDownloadFanSubmissions({ ids, socketId });
    if ('error' in result) {
      addFlashMessage(t('global.unexpectedError'), { isError: true });
    } else {
      setBulkDownloadUuid(result.data.uuid);
    }
  };

  const isLoading = isStartingDownload || !!bulkDownloadUuid;

  return (
    <FanSubmissionBulkAction
      title={
        isLoading
          ? t('fanRequestShow.submissions.bulkActions.preparingDownload')
          : t('fanRequestShow.submissions.bulkActions.download')
      }
      icon={isLoading ? IconLoader : IconDownload}
      onClick={handleDownload}
    />
  );
}
