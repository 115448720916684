import { AnimatePresence, motion } from 'framer-motion';
import { IconRemove } from 'icons';
import { useTranslation } from 'react-i18next';

interface Props {
  isVisible: boolean;
  onDismiss: () => void;
  onContinue: () => void;
}

export default function SignUpExistingAccountWarning(props: Props) {
  const { isVisible, onDismiss, onContinue } = props;

  const { t } = useTranslation();
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="notice notice--callout mt-1.5"
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
        >
          <div className="flex-1 flex items-center justify-between">
            <p>
              {t('onboarding.signUp.main.notice')}{' '}
              <span
                className="font-bold underline cursor-pointer"
                // TODO: Changing this to new sign in route once that screen is created
                onClick={onContinue}
                role="button"
              >
                {t('global.signIn')}
              </span>
            </p>
            <div
              role="button"
              className="flex-1 flex items-center justify-end"
              onClick={onDismiss}
            >
              <IconRemove className="h-2 w-2 text-info opacity-20" />
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
