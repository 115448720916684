interface Props {
  title: React.ReactNode;
  description?: React.ReactNode;
  children: React.ReactNode;
}

export default function Section(props: Props) {
  const { title, description, children } = props;

  return (
    <div className="mb-6.5 pb-6 border-b-default last:border-b-0">
      <div className="mb-4">
        <div className="h5 mb-0.25 last:mb-0">{title}</div>

        {!!description && (
          <div className="text-light text-14">{description}</div>
        )}
      </div>

      {children}
    </div>
  );
}
