import React, { useCallback, useState, useRef } from 'react';
import cx from 'classnames';
import Tooltip, { TooltipProps } from './Tooltip';
import hasClass from 'helpers/hasClass';
import noPropagate from 'helpers/noPropagate';
import { TippyProps } from '@tippyjs/react';
import useClickOutside from 'hooks/useClickOutside';

interface Props {
  children: React.ReactNode;
  placement?: TippyProps['placement'];
  theme?: TooltipProps['theme'];
  renderHeading?: React.ReactNode;
  renderButton: (
    onClick: any,
    isOpen: boolean,
    ref: React.RefObject<HTMLButtonElement>
  ) => TippyProps['children'];
  offset?: TippyProps['offset'];
  onOpen?: () => void;
  onOpened?: () => void;
}

export default function FlyoutMenu(props: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen((isOpen) => !isOpen);

  const clickOut = useCallback(
    (e: any) => {
      if (!isOpen || hasClass(e.target, 'tippy-box')) return;
      if (buttonRef.current === e.target) return;
      if (buttonRef.current?.contains(e.target)) return;
      setIsOpen(false);
    },
    [isOpen]
  );

  useClickOutside(wrapperRef, clickOut);

  const { children, renderHeading, renderButton, placement } = props;

  return (
    <Tooltip
      placement={placement || 'bottom-start'}
      visible={isOpen}
      theme="FlyoutMenu"
      interactive
      offset={props.offset || [0, 4]}
      duration={[0, 0]}
      delay={[0, 0]}
      onShow={props.onOpen || (() => {})}
      onShown={props.onOpened || (() => {})}
      content={
        <div ref={wrapperRef}>
          {renderHeading}

          {React.Children.map(children, (child) => {
            const props = (child as any) && (child as any).props;
            const disabled = props && props.disabled;
            return (
              <div
                onClick={() => setIsOpen(false)}
                className={cx('flyout-menu-item', {
                  'flyout-menu-item-disabled': disabled,
                })}
              >
                {child}
              </div>
            );
          })}
        </div>
      }
    >
      {renderButton(noPropagate(toggleOpen), isOpen, buttonRef)}
    </Tooltip>
  );
}
